import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../products/services/products.service';
import { Product } from '../products/interfaces/product';
import { CategoryService } from '../categories/services/category.service';
import { Category } from '../categories/interfaces/category';
import { StockService } from '../stocks/services/stock.service';
import { Stock } from '../stocks/interfaces/stocks';
import {AuthService} from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { ExportService } from './services/export.service';
import { Combination } from '../combinations/interfaces/combination';
import { Price } from '../prices/interfaces/prices';

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.css']
})
export class ExportsComponent implements OnInit {

  products: Product[] = []
  productsCSV: any[] = []
  combinationsCSV: any[] = []
  categories: Category[] = []
  stocks: Stock[] = []
  combinations: Combination[] = []
  prices: Price[] = []
  status_loop: boolean = false
  constructor(
    private exportService: ExportService,
    private productsService: ProductsService, 
    private auth: AuthService,
    private categoriesService: CategoryService, 
    private stocksService: StockService, 
    private Notify: SnotifyService,
  ) { }

  ngOnInit() {
  }
  download(name){
    this.status_loop = true
    switch (name) {
      case 'products':
        this.productsService.getProductsExport().subscribe(
          data => {
            this.status_loop = false;
            this.Notify.success("Products descargados",{timeout:1000});//0 = gets default timeout
            this.products = data['products']
            this.productsCSV = [];
            this.products.forEach(product => {
              
              let images = {...product.images}
/*               let associations = JSON.stringify(product['associations']).split(',').join('--comaCSV--')
              product['associations'] = associations; */
              let productCSV = {...product, ...images}
              delete productCSV['images'];
              this.productsCSV.push(productCSV);
            })
          },
          error => {
            this.status_loop = false;
            this.auth.handleError(error)
          }
        )
        break;
      case 'categories':
        this.categoriesService.getCategoriesExport().subscribe(
          data => {
            this.status_loop = false;
            this.Notify.success("Categories descargados",{timeout:1000});//0 = gets default timeout
            this.categories = data['categories']
          },
          error => {
            this.status_loop = false;
            this.auth.handleError(error)
          }
        )
        break;
      case 'stocks':
        this.exportService.getStocsksExport().subscribe(
          data => {
            this.status_loop = false;
            this.Notify.success("Stocks descargados",{timeout:1000});//0 = gets default timeout
            this.stocks = data['stocks']
          },
          error => {
            this.status_loop = false;
            this.auth.handleError(error)
          }
        )
        break;
      case 'combinations':
        this.exportService.getCombinationsExport().subscribe(
          data => {
            this.status_loop = false;
            this.Notify.success("Combinations descargados",{timeout:1000});//0 = gets default timeout
            this.combinations = data['combinations'];
            this.combinations.forEach(combination => {
              let combinationCSV = {...combination}
              combinationCSV['associations'] = combination.associations && combination.associations['product_option_values'] ? combination.associations['product_option_values'] : []
              this.combinationsCSV.push(combinationCSV);
            })
          },
          error => {
            this.status_loop = false;
            this.auth.handleError(error)
          }
        )
        break;
      case 'prices':
        this.exportService.getPricesExport().subscribe(
          data => {
            this.status_loop = false;
            this.Notify.success("Prices descargados",{timeout:1000});//0 = gets default timeout
            this.prices = data['prices']
          },
          error => {
            this.status_loop = false;
            this.auth.handleError(error)
          }
        )
        break;
    
      default:
        break;
    }
  }

  exportProducts(){
    this.generateFile(this.products, 'products.json')
  }
  exportCategories(){
    this.generateFile(this.categories, 'categories.json')
  }
  exportStocks(){
    this.generateFile(this.stocks, 'stocks.json')
  }
  exportCombinations(){
    this.generateFile(this.combinations, 'combinations.json')
  }
  exportPrices(){
    this.generateFile(this.prices, 'prices.json')
  }

  generateFile(item, namefile){
    let blob =new Blob([JSON.stringify(item)],{type:"data:text/json;charset=utf-8"});
    let url = window.URL.createObjectURL(blob)
    let downloader = document.createElement('a');

    let evt = new MouseEvent("click", {
      "view": window,
      "bubbles": true,
      "cancelable": false
    });

    downloader.setAttribute('href', url);
    downloader.setAttribute('href', url);
    downloader.setAttribute('download', namefile);
    downloader.setAttribute('textContext', `Download ${namefile}`);
    downloader.dispatchEvent(evt);
  }

}