import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService, private translate: TranslateService) { }


  getShops(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/shops',{ headers });
  }
  
  getLanguages(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/languages',{ headers });
  }

}
