import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { TokenService } from '../../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'ingreso',
  templateUrl: './ingreso.component.html',
})
export class IngresoComponent implements OnInit {

  ingresoForm: FormGroup;
  @Output() cancel = new EventEmitter();


  
  constructor(
    private Auth : AuthService,
    private tokenService: TokenService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private fb: FormBuilder 
  ) { 
    this.ingresoForm = this.fb.group({
      'quantity': new FormControl('', Validators.compose([Validators.required, Validators.min(0.01)])),
    })
  }

  ngOnInit() {
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  guardar(form){
  }
  
  cancelForm(){
    this.cancel.emit(true)
  }
  
}
