import { Component, OnInit, IterableDiffers } from '@angular/core';
import { Product } from './interfaces/product';
import { ProductsService } from './services/products.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Message } from 'primeng/components/common/message';
import { environment } from '../../../environments/environment';
import { Category } from '../categories/interfaces/category';
import { CategoryService } from '../categories/services/category.service';
import { BrandService } from '../brands/services/brand.service';
import { Brand } from '../brands/interfaces/brand';
import { Combination } from '../combinations/interfaces/combination';
import { ProductOption } from '../combinations/interfaces/product_options'
import { ProductOptionsService } from '../combinations/services/product_options.service';
import { Stock } from '../stocks/interfaces/stocks'
import { CombinationService } from '../combinations/services/combination.service';
import { StockService } from '../stocks/services/stock.service';
import { Price } from '../prices/interfaces/prices';
import { UtilService } from '../../services/utils.service';
import * as jQuery from 'jquery';
import {TranslateService} from '@ngx-translate/core'




@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {


  environment = environment;
  visibleSidebar5 = true;

  product:Product = {
    _id:"",
    name : "",
    description_short :"",
    description_long :"",
    image_cover : "",
    images : {},
    active : false,
    sku : "",
    sku_supplier : "",
    ean13 : "",
    category_default :"",
    categories : "",
    position : "",
    cost_price : null,
    price : null,
    tax : null,
    activation_date : "",
    order_without_stock : false,
    brand : "",
    validated: false,
    lang:'es',
    link_rewrite:'',
  }
  checked: boolean = true;
  nuevo: boolean = false;
  id:string;

  msgs: Message[];
    
  uploadedFiles: any[] = [];
  uploadedFiles2: any[] = [];

  categories: any=[];
  selectedCategory: any;
  category: any = {}
/*     id:"",
    image: "",
    name : "",
    active : false,
    parent_id : null,
    parent_name : null,
    description: ""
  } */

  selectedCategories : Category[]=[];

  brands: Brand[]=[];
  selectedBrand: Brand;
  brand: Brand = {
    _id: "",
    name: "",
    active : true,
    user: "",
    image: ""
  }
    displayNewBrand = false;

  /**
   * Slim utils
   */
  

   actionType: String = "Create";
  imagesForm: FormGroup;
  credentials:any;
  slimOptions = {
    ratio: '1:1',
    download: true,
    filterSharpen: '10',
    willRemove: this.imageWillBeRemoved.bind(this),
    didConfirm: this.imageDidBeConfirmed.bind(this),
    instantEdit: true,
    label: '<i class="fa fa-picture-o" aria-hidden="true"></i>',
    buttonConfirmLabel: 'Confirmar',
    buttonConfirmTitle: 'Confirmar',
    buttonCancelLabel: 'Cancelar',
    buttonCancelTitle: 'Cancelar',
    buttonEditTitle: 'Editar',
    buttonRemoveTitle: 'Eliminar',
    buttonDownloadTitle: 'Descargar',
    buttonRotateTitle: 'Rotar',
    buttonUploadTitle: 'Subir',
    statusImageTooSmall:'Imagen demasiado pequeña por favor inténtelo con otra'
  };
  optionsSlim:Object[] = [];//contiene los slimpOptions con la imagen por defecto 
  imagesRep = ['image0','image1','image2','image3'];
  imagesBase64:string[]= []; 

  /**
   * end Slim utils
   */
  
   /**
    * Combinations 
    */
    combinations: Combination[] = [] 
    cp_combinations: Combination[] = [];
    /**
     * Stocks
     */
    iterableDiffer;
    attribute_values: any;
    attributes : ProductOption[];
    stocks:Stock[] = []
    prices:Price[] = []
  /**
   * Form Validation
   */
  productForm: FormGroup;

  nameFormControl:FormControl = new FormControl('', Validators.compose([Validators.required]));
  isLinkRewrite = /^[_a-zA-Z0-9-]+$/;
  isCatalogName = /^[^<>;=#{}]*$/u;
  isReference = /^[^<>;={}]*$/u
  isEan13 = /^[0-9]{0,13}$/;

  link_rewriteFormControl = [Validators.required, Validators.pattern(this.isLinkRewrite), Validators.maxLength(4)];
  
  constructor(private productService : ProductsService,
    private tokenService : TokenService, 
    private auth: AuthService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private categoryService : CategoryService,
    private brandService : BrandService,
    private _iterableDiffers : IterableDiffers,
    private combinationService : CombinationService,
    private translateService : TranslateService,
    private productOptionsService : ProductOptionsService,
    private stockService : StockService,
    private utils : UtilService,
    private fb: FormBuilder 
  ) {



    this.activatedRoute.params.subscribe(
      parametros =>{
        this.id=parametros['id'];
      });

    this.activatedRoute.data.subscribe(
      (response) => {
        if(response.data && response.data.product){
          this.product = response.data.product
          this.combinations = response.data.associations.combinations
          this.stocks = response.data.associations.stocks
          this.prices = response.data.associations.prices
/*           this.categoryService.getCategory(this.product.category_default).subscribe(
            datacategory => {
              this.category = datacategory['category'];
              this.selectedCategory = this.category;
            },
            error => this.auth.handleError(error)
          ); */
          if(this.product.brand){
            this.brandService.getBrand(this.product.brand).subscribe(
              databrand => {
                this.brand = databrand['brand'];
                this.selectedBrand = this.brand;
              },
              error => this.auth.handleError(error)
            );
          }
        }
        if(response.data_brands && response.data_brands.brands){
          this.brands = response.data_brands.brands
        }
      },
        error => this.auth.handleError(error)
    )
    
    this.iterableDiffer = this._iterableDiffers.find([]).create(null)
    this.productOptionsService.getProductOptions().subscribe(
      data => {
        this.attributes = data['product_options']
      },
        error => this.auth.handleError(error)
    )
    this.productOptionsService.getProductOptionsValues().subscribe(
      data => {
        this.attribute_values = data['product_option_values']
      },
      error => this.auth.handleError(error)
    )

    this.productForm = this.fb.group({
      'name': new FormControl('', Validators.compose([Validators.required, Validators.maxLength(128), Validators.pattern(this.isCatalogName)])),
      'description_short': new FormControl(''),
      'description_long': new FormControl(''),
      'sku': new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.isReference), Validators.maxLength(32)])),
      'price': new FormControl('', Validators.compose([Validators.required, Validators.min(0)])),
      'categoryDefault': new FormControl('', Validators.compose([Validators.required])),
      'categoriesAsociadas': new FormControl(''),
      'position': new FormControl(''),
      'sku_supplier': new FormControl('', ),
      'ean13': new FormControl('', Validators.compose([Validators.pattern(this.isEan13)])),
      'cost_price': new FormControl(''),
      'activation_date': new FormControl(''),
      'brandDefault': new FormControl(''),
      'link_rewrite': new FormControl('', Validators.compose([Validators.required, Validators.pattern(this.isLinkRewrite)])),
    })
    //initialize stocks
    this.generateStocks()
  }
   
 changeActiveStatus(){
  this.product.active = !this.product.active;
}
changeOrderStockStatus(){
  this.product.order_without_stock = !this.product.order_without_stock;
}

myCloseEvent(){
  this._router.navigate(['/products']);
}
test(e){
  console.log(e)
}
  
ngOnInit() {
  console.log(this.product)
  this.generateOptions();
  this.categoryService.getCategoriesAll().subscribe(
    data => {
      this.categories = data['categories'];
      this.utils.processExposeName(this.categories);
      if(this.product.categories){
        this.product.categories.forEach((category_id) => {
          this.selectedCategories.push(this.categories.find((category) => {
            return category_id == category._id
          })) 
        })
      }
      if(this.product.category_default){
        this.selectedCategory = this.categories.find((category) => {
          return category._id == this.product.category_default;
        });
        
      }
      
    },
    error=> this.auth.handleError(error)
  )
}
  createStocksCombinations(combinations, stocks, product_id){
    stocks.forEach((stock, i) => {
      stock.product_id= product_id;
      if(combinations[i] && combinations[i]._id){
        stock.combination_id = combinations[i]._id;
      }else{
        stock.combination_id = 0;
      }
    });
    this.stockService.newStocks(stocks).subscribe(
      data => {
        this.Notify.success("Stocks creados", {timeout: 1000});
      },
      error=>{
        this.auth.handleError(error);
      }
    )
  }

  createCombinations(combinations,id){
    combinations.forEach(combintaion => {
      combintaion.id_product = id;
    });
    this.combinationService.newCombinations(combinations).subscribe(
      data=>{
        this.Notify.success("Combinaciones del producto creado",{timeout:1000});//0 = gets default timeout
        this.createStocksCombinations(data['combinations'], this.stocks, id);
      },
      error=>{
        this.auth.handleError(error)
      }
    )
  }

guardar(form: NgForm){
  let sendDatos = {};
  this.imagesRep.forEach((name, index) => {
    sendDatos[name] = this.imagesBase64[index] || "" ;
  })
  this.product.images = sendDatos;
  if(this.selectedBrand){
    this.product.brand = this.selectedBrand._id
  }else{
    this.product.brand = "" 
  }
  if(this.selectedCategory){
    this.product.category_default = this.selectedCategory._id;
  }else{
    this.product.category_default = "";
  }
  if(this.productForm.value.categoriesAsociadas){
    this.product.categories = [];
    this.productForm.value.categoriesAsociadas.forEach(category => {
      if(category && category.id){
        this.product.categories.push(category._id);
      }
    });
  }
  this.product.lang = this.translateService.currentLang ? this.translateService.currentLang : 'es' ;
  if(this.id == 'add'){
    //insert
    this.productService.newProduct(this.product).subscribe(
    data=>{
      if(this.combinations.length > 0){
        this.createCombinations(this.combinations, data['product']._id)//if combinations dont have id it can created
      }else{
        this.createStocksCombinations([],this.stocks, data['product']._id)
      }
      this.Notify.success("Producto creado",{timeout:1000});//0 = gets default timeout
      setTimeout(() => {
        this._router.navigate(['/products']);
      },2000);
    },
    error=>{
      if(error.status === 403 && error.error && error.error.errors && error.error.errors === "Exist product with this reference"){
        this.Notify.warning('La referencia esta ya registrada prueba otra !', {timeout: 2000}) 
        this.productForm.controls.sku.reset()
      }else{
        this.auth.handleError(error)
      }
    }
    )
  }else{
    //update
    this.productService.updateProduct(this.product, this.id).subscribe(
      data=>{
        this.Notify.info("Producto editado",{timeout:1000});//0 = gets default timeout
        setTimeout(() => {
          this._router.navigate(['/products']);
        },2000);
      },
      error=>{
        if(error.status === 403 && error.error && error.error.errors && error.error.errors === "Exist product with this reference"){
          this.Notify.warning('La referencia esta ya registrada prueba otra !', {timeout: 2000}) 
          this.productForm.controls.sku.reset()
        }else{
          this.auth.handleError(error)
        }
      }
    )
  } 
}

agregarNuevo(form : NgForm){
  this._router.navigate(['/product','add']);
  form.reset();
}

/**
 * Stocks functions
 */
    generateStocks(){
    let newStocks = [] 
    if(this.combinations.length >= 1){
      this.combinations.forEach((combination, i) => {
        if(combination._id){
          let currentStock = this.stocks.find(stock => { return stock.combination_id == combination._id})
          if(currentStock){
            newStocks.push(currentStock)
          }else{
              newStocks.push({_id: "", product_id: this.product._id, combination_id: combination._id, quantity: 0, user: "" })
          }
        }else{
          if(this.stocks[i]){
            newStocks.push(this.stocks[i]);
          }else{
            newStocks.push({_id: "", product_id: this.product._id, combination_id: combination._id, quantity: 0, user: "" })
          }
        }
      })
    }else{
      if(this.stocks.length == 0 || (this.stocks[0].combination_id && this.stocks[0].combination_id != '0' && this.combinations.length == 0)){
        newStocks.push({_id: "", product_id: this.product._id, combination_id: '0', quantity: 0, user: "" })
      }else{
        let currentStock = this.stocks[0]
       if(currentStock.combination_id == '0'){
          newStocks.push(currentStock);
       }else{
          newStocks = this.stocks;
       }
      }
    }
    this.stocks = newStocks
  }

  ngDoCheck() { //detect change in combination and generate the news stocks
    let changes = this.iterableDiffer.diff(this.combinations);
    if (changes) {
        this.generateStocks()
    }
  }

  newBrand(id){
    this.displayNewBrand = false;
    if(id.brand){
      let brand:Brand = id.brand
      this.brands = [brand, ...this.brands]//no se puede usar push ya que no lo detecta como un cambio
      this.selectedBrand = id.brand
    }
  }
  
  validateFirstImage(){
    if(this.imagesBase64 && this.imagesBase64[0]){
      return true;
    }
    return false;
  }

  validForm(){
    return this.productForm.valid && this.validateFirstImage()
  }

  createCombinationsForPrice(combinations, attributes, attribute_values){
    let combinationsPrices = []
    combinations.forEach(combination => {
      combinationsPrices.push({id: combination._id, name: this.utils.getAttributeValueOfCombination(combination, attributes, attribute_values)})
    })
    return this.utils.addAll([...combinationsPrices], 'combinations')
  }

  goTop(){
    jQuery('#top').animate({ scrollTop: 0 }, 'slow');
  }

/**
 * slim functions
 */

  changeImage(e:any, i:any){//save image in base64
    e.preventDefault()
    let files = e.target.files || e.dataTransfer.files;
    let file = files[0];
    if(file){
      let reader = new FileReader;
      reader.onloadend = ()=>{
        if(typeof reader.result === 'string' && reader.result.match('data:image')){
          this.saveBase64(reader.result, i)//save base64
        }
      }
      reader.readAsDataURL(file);
    }
  }

  imageWillBeRemoved(data, that, remove) {
    if (window.confirm("Are you sure?")) {
      remove();
      let key = this.imagesRep.indexOf(that._input.name)//clave de la image
      this.imagesBase64[key] = undefined;
    }
  }

  imageDidBeConfirmed(data, that) {
    let key = this.imagesRep.indexOf(that._input.name)
    this.imagesBase64[key] = that.dataBase64.output.image
  }

  limitMinImages(){
    return (this.imagesBase64.filter(Boolean).length >= 1)
  }

  saveBase64(base64, i){
      this.imagesBase64[i] = base64
  } 

  generateOptions(){
    this.imagesRep.forEach((name,i)=> {
      this.optionsSlim[name] = this.getImageDefault(name,i)
    })
  } 

  getImageDefault(name,i){
    if(this.product.images[name]){
      this.saveBase64(this.product.images[name], i)
      if(i == 0){
        return { ...this.slimOptions, 'initialImage': this.product.images[name], 'label': this.translateService.instant('Esta imagen será la portada de tu producto')+this.slimOptions.label};
      }
      return { ...this.slimOptions, 'initialImage': this.product.images[name] };
    }
    if(i == 0){
      return { ...this.slimOptions, 'initialImage': this.product.images[name], 'label':  this.translateService.instant('Esta imagen será la portada de tu producto')+this.slimOptions.label};
    }
    return this.slimOptions
  }
}

