import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {  CarrierService } from './services/carrier.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { UtilService } from '../../services/utils.service';


@Component({
  selector: 'carrier',
  templateUrl: './carrier.component.html'
})
export class CarrierComponent implements OnInit {

  @Input() htmlentity: boolean = true;
  nuevo: boolean = false;
  id:string;
  peso:any = {
    maximo:10,
    minimo:0,
    precio:0
  }
  zones: any =[];
  currentRange:any;
  selectRang:any;
  ranges:any=[];
  currentRangeY:any={};
  carrierForm: FormGroup;
  rangesCreated:any=[]

  constructor(private carrierService : CarrierService, 
              private auth: AuthService, 
              private utils: UtilService,
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private fb: FormBuilder,
              private Notify: SnotifyService) {
              

                this.carrierService.getZones().subscribe(
                  data => {
                    this.zones = data['zones'];
                    this.utils.processExposeName(this.zones)
                    console.log(this.zones)
                  },
                  error => this.auth.handleError(error)
                )


            this.carrierForm = this.fb.group({
              'weight_min': new FormControl(0, Validators.compose([Validators.required, this.validateRanges('min').bind(this), this.validateMin().bind(this)])),
              'weight_max': new FormControl(0, Validators.compose([Validators.required, this.validateRanges('max').bind(this), this.validateMax().bind(this)])),
            })
    
            
/*             if(!this.htmlentity){
              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.brandService.getBrand(this.id).subscribe(
                      data => {
                        console.log(data);
                        this.brand = data['brand'];
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
            } */
            }

  ngOnInit() {
    this.carrierService.getCarrier().subscribe(
      data => {
        console.log(data);
        this.ranges = data['ranges'];
      }
    )
  }

  guardar(){
    if(this.ranges.indexOf(this.currentRange) === -1){
      this.ranges = [
          ...this.ranges,
          this.currentRange
      ]//cannot use push
    }
    this.currentRange = undefined;
    console.log({ranges: this.ranges})
/*     let id_carrier = JSON.parse(localStorage.getItem('user')).id_carrier;
    this.carrierService.updateCarrier({"id_carrier":id_carrier,precio:this.peso.precio,"peso":[this.peso.minimo,this.peso.maximo]}); */
  }

  send(){
    this.carrierService.newCarrier({ranges: this.ranges}).subscribe(
      data => {
        console.log(data)
      },
      error => this.auth.handleError(error)
    )
  }

  newRange(){
    let zones = [] 
    this.zones.forEach(z => {
      let zone = {id: z.id, name:z.exposeName, price:0,checked:false}
      zones.push(zone)
    });
    let range = {
      weight_min: "",
      weight_max: "",
      priceAll: "",
      checkAll:false,
      zones
    }
    this.currentRange = range
  }

  selectRange(){
    this.selectRang = {
      weight_min: this.currentRange.weight_min,
      weight_max: this.currentRange.weight_max
    }
  }

  validateRanges(type): ValidatorFn{
      return (control: AbstractControl): {[key: string]: boolean} | null => {
        if(!control.value){
          return {'validDates': true}
        }
        let value = parseFloat(control.value)
        let hasError = false;
        let valueOpositor = parseFloat(this.currentRange.weight_max) 
        if(type === 'max'){
          valueOpositor = parseFloat(this.currentRange.weight_min) 
        }
        this.ranges.forEach(range => {
          if( (value >= parseFloat(range.weight_min) && value <= parseFloat(range.weight_max)) ||
              (value <= parseFloat(range.weight_min) && valueOpositor >= parseFloat(range.weight_max) ||
              (value >= parseFloat(range.weight_min) && valueOpositor <= parseFloat(range.weight_max)) )){
            if(this.currentRange && (this.currentRange.weight_min === range.weight_min || this.currentRange.weight_max === range.weight_max)){

            }else{
              hasError = true
            }
          }
        })
        if(hasError){
          return {'validDates': true}
        }
        return null
    }
  }

  validateMax(): ValidatorFn{
      return (control: AbstractControl): {[key: string]: boolean} | null => {
        if(!control.value){
          return {'validDates': true}
        }
        let value = parseFloat(control.value)
        if(this.currentRange.weight_min && parseFloat(this.currentRange.weight_min) >= value){
          return {'validDates': true}
        }
        return null
    }
  }

  validateMin(): ValidatorFn{
      return (control: AbstractControl): {[key: string]: boolean} | null => {
        if(!control.value){
          return {'validDates': true}
        }
        let value = parseFloat(control.value)
        if(this.currentRange.weight_max && parseFloat(this.currentRange.weight_max) <= value){
          return {'validDates': true}
        }
        return null
    }
  }
}
