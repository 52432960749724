import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../../products/services/products.service';
import { TokenService } from '../../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import {LightboxModule} from 'primeng/lightbox';
import {TranslateService} from '@ngx-translate/core'

@Component({
  selector: 'supervise',
  templateUrl: './superviseItems.component.html'
})
export class SuperviseItemsComponent implements OnInit {


  typesColumns = { 
    "Product": [
      {name:this.getLang('Imagen'), type:"image", value:"images", childs:["image0"]},
      {name:this.getLang('nombre'), type:"text", value:"name"},
      {name:this.getLang('Precio'), type:"text", value:"price"},
      {name:this.getLang('Validado'), type:"switch", value:"validated"}],
    "User": [
      {name:this.getLang('Imagen'), type:"image", value:"image"},
      {name:this.getLang('email'), type:"text", value:"email"},
      {name:this.getLang('nombre'), type:"text", value:"name"},
      {name:this.getLang('Validado'), type:"switch", value:"validated"}],
  }
  columns = []
  items: any[] = [];
  type: string ="Product";
  pages:number;
  constructor(private productService: ProductsService, 
    private tokenService : TokenService,
    private _router : Router, 
    private Auth : AuthService,
    private translateService : TranslateService,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
  ) {
    this.activatedRoute.params.subscribe(
      parametros =>{//de momento solo accesible con products o user
        if(parametros['type'] == "product" || parametros['type'] == "user") {
          this.type=parametros['type'].charAt(0).toUpperCase() + parametros['type'].slice(1);
          this.items = []
          this.columns = this.typesColumns[this.type]
          this.getResources(this.type)
        }
      });
   }

  ngOnInit() {
  } 

  getPage(page){
    this.getResources(this.type, page+1)
  }
  getResources(type, page=1){
    switch (type) {
      case "Product":
        this.productService.getProductsPaginate(page).subscribe(
          data => {
            this.items = this.proccessValidated(data['products']);
            this.pages = data['last_page'];
          },
          error=> this.Auth.handleError(error)
        )
        break;

      case "User":
        this.Auth.getProfiles().subscribe(
          data => {
            this.items = this.proccessValidated(data['users']);
            this.pages = data['last_page'];
          },
          error=> this.Auth.handleError(error)
        ) 
        break;
    
      default:
        break;
    }
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  statusClick(item){
    switch (this.type) {
      case "Product":
        this.productService.updateProduct(item, item._id).subscribe(
          data => {
            this.Notify.info(`${this.type} editado`,{timeout:1000});//0 = gets default timeout
          },
          error=> this.Auth.handleError(error)
        )
        break;
    
      case "User":
        this.Auth.updateProfile(item, item._id).subscribe(
          data=>{
            this.Notify.info(`${this.type} editado`,{timeout:1000});//0 = gets default timeout
          },
          error=>this.Auth.handleError(error)
        )
      
        break;

      default:
        break;
    }
  }

  proccessChilds(column, item){
    if(!column.childs){
      return item[column.value];
    }else{
      let value = item[column.value]
      column.childs.forEach(child => {
        if(value){
          value = value[child]
        }
      });
      if(value){
        return value;
      }
      return "empty"
    }
  }

  /**
   * 
   * @param items Object[] Contain all item to validate
   * @description Comprueba si los items tiene la propiedad validated y si no la tienen la crear a false 
   */
  proccessValidated(items){
    items.forEach((item, i) => {
      if(!item.validated){
        items[i].validated = false
      }
    });
    return items
  }
  getLang(lang_key){
    return this.translateService.instant(lang_key);
  }
  getTypeLang(){
    switch (this.type) {
      case "Product":
        return this.translateService.instant('Productos')
    
      case "User":
        return this.translateService.instant('Usuarios')

      default:
        return this.translateService.instant('Productos')
    }

  }

}
