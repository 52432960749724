import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { trigger } from '@angular/animations';
import { UtilService } from '../../../services/utils.service';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  public name = null;
  public loggedIn:boolean;
  public isAdmin:boolean;
  cants =  [{name: 'items10', value:'10'},
            {name: 'items20', value:'20'},
            {name: 'items30', value:'30'}]
  cant = {name: 'items10', value:'10'}

  @Input() page: number;
  @Input() pages: number = 1;
  @Output() triggerPage = new EventEmitter();
  @Output() triggerCant = new EventEmitter();
    
  constructor(private Auth: AuthService,
              private router: Router, 
              private utils : UtilService,
              private translate: TranslateService,
            ) { }

  ngOnInit() {
    }

    getPage(page){
      if(page > this.pages){
        this.page = this.pages;
        return;
      }else if(page < 1){
        this.page = 1;
        return;
      }
      this.triggerPage.emit(page);
    }

    changeCant(){
      this.triggerCant.emit(this.cant.value)
    }


}
