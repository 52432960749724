import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AttributeService } from './services/attribute.service';
import { Attribute_valueService} from './services/attribute_values.service'
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Attribute, AttributeValue, AttributeValues } from './interfaces/attribute';

@Component({
  selector: 'app-attribute',
  templateUrl: './attribute.component.html',
  styleUrls: ['./attribute.component.css']
})
export class AttributeComponent implements OnInit {

  selected:any;
  
  attribute: Attribute = {
    _id:"",
    name : [],
    categories : "",
    values :[]
  }

  languagesAll=[
    {name: 'Español',iso: 'es',disabled:false},
    {name: 'Ingles',iso: 'en',disabled:false},
    {name: 'Francés',iso: 'fr',disabled:false},

  ];

  languagestranslated=[
   /*  {name: 'Español',iso: 'ES'}, */
    {name: 'Ingles',iso: 'en'}
  ];

  values_attr: string[] = [];


  attributes : Attribute[]=[];
  values:any;
  nuevo: boolean = false;
  id:string;

  constructor(private attributeService : AttributeService, 
              private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService,
              private attributeValuesService: Attribute_valueService
            ) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.attributeService.getAttribute(this.id).subscribe(
                      data => {
                        this.attribute = data['attribute'];
                        this.attribute.name = this.addLanguages(this.attribute.name);
                        if(this.attribute.values){
                          this.attribute.values.forEach((value) => {
                            value.name = this.addLanguages(value.name)
                          })
                          this.attribute.values.push(this.generateNewAttributesValues())//add blank AttributesValues
                          }
                      },
                      error => this.Auth.handleError(error)
                    );
                  }else{
                    this.attribute = {    
                      _id:"",
                      name : this.addLanguages([]),
                      categories : "",
                      values :[
                        this.generateNewAttributesValues()
                      ]
                  }

                  }
                }
              )
            }

  ngOnInit() {
    this.selected = 'es';
  }

  addLanguages(items,){
  if(!items){
    items=[];
  }
  this.languagesAll.forEach((lang) => {
    let exist = false;
    let langO = {'lang':'', 'value': ''}
    items.forEach((val) => {
      if(val.lang == lang.iso){
        exist = true
      }
    })
    if(!exist){
      langO.lang = lang.iso
      items.push(langO as AttributeValue)
    }
  })
  return items
  }
  customTrackBy(index: number, obj: any): any {
    return index;
  }

  generateNewAttributesValues(){
    return {
      _id: "",
      attribute_id: this.attribute._id,
      created_at: "",
      updated_at: "",
      name: this.addLanguages([])
    } as AttributeValues
  }

  handleError(error){
      if(error.error[0]){
        this.Auth.changeAuthStatus(false);
        this.tokenService.remove();
        this._router.navigateByUrl('login');
      }
    }

  guardar(){ 
    let fakeAttribute = {...this.attribute}
    let fakeAttributeValues = [...this.attribute.values]
    fakeAttribute.values = []

    if(this.id == 'add'){
      //insert
      this.attributeService.newAttribute(fakeAttribute).subscribe(
        data=>{
          this.Notify.success("Atributo creado",{timeout:1000});//0 = gets default timeout
          this.updateOrCreateValues(fakeAttributeValues, data['attribute']._id);
          setTimeout(() => {
            this._router.navigate(['/attributes']);
          },2000);
        },
        error=>this.Auth.handleError(error)
      )
    }else{
       this.attributeService.updateAttribute(fakeAttribute, this.id).subscribe( 
         data=>{ // this._router.navigate(['/catalogs',data['catalog']._id])
          this.Notify.info("Atributo editado",{timeout:1000});//0 = gets default timeout 
          this.updateOrCreateValues(fakeAttributeValues)
          setTimeout(() => { 
            this._router.navigate(['/attributes']); 
          },2000); 
        }, 
        error=>this.Auth.handleError(error)
      ) 
    }
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/attribute','add']);
    form.reset();
  }

  updateOrCreateValues(values, _id = undefined){
    values.forEach((attributesValues) => {
      if(!attributesValues._id){
        let empty = attributesValues.name.some(function(value){
          if(value.value){
            return true
          }
        });
        if(empty && (_id || attributesValues.attribute_id)){//if new is empty dont create 
          if(!attributesValues.attribute_id){
            attributesValues.attribute_id = _id
          }
          this.attributeValuesService.newAttribute_value(attributesValues).subscribe(
            data => {
            },
            error=>this.Auth.handleError(error)
          )
        }
      }else{
        this.attributeValuesService.updateAttribute_value(attributesValues, attributesValues._id).subscribe(
          data => {
          },
          error=>this.Auth.handleError(error)
        )
      }
    })
    this.Notify.success("Valores del Atributo creados",{timeout:100});
  }

  addNewValue(){
    this.attribute.values.push(this.generateNewAttributesValues())//add blank AttributesValues
  }
  
  removeAttirbuteValue(e){
    this.attribute.values.splice(e,1)
  }

}
