import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PrestashopService {

  constructor(
    private http: HttpClient, 
    private configurationService : ConfigurationService,
  ) { }
  

  getProductOptions(){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_options/?io_format=JSON&ws_key=${this.configurationService.getWs_key()}&display=full`,{});
  }

  getProductOptionsValues(){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?io_format=JSON&ws_key=${this.configurationService.getWs_key()}&display=full`,{});
  }

  getProductOptionValuesByIdProductOption(id){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?display=full&io_format=JSON&ws_key=${this.configurationService.getWs_key()}&filter[id_attribute_group]=[${id}]`,{});
  }

  getProductOptionByIdProductOption(id){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?display=full&io_format=JSON&ws_key=${this.configurationService.getWs_key()}&filter[id]=[${id}]`,{});
  }

  getPS(resource, id, display, filters){
      let filt = ""
      filters.forEach(filter => {
          let str = `&filter[${filter.key}]=${filter.value}`;
          filt += str;
      });
     return this.http.get(`${this.configurationService.getDomain_ps()}api/${resource}/${id}?display=${display}&io_format=JSON&ws_key=${this.configurationService.getWs_key()+filt}`,{});
  }

}
