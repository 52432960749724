import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Attribute_valueService } from '../services/attribute_values.service';
import { TokenService } from '../../../services/token.service';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Attribute, AttributeValue } from '../interfaces/attribute';

@Component({
  selector: 'app-attributevalues',
  templateUrl: './attribute_values.component.html'
})
export class AttributeValuesComponent implements OnInit {
  @Input() languages; 
  @Input() selected: string; 
  @Output() changeValue = new EventEmitter();
  @Input() value: AttributeValue[];
  @Input() index: number;
  @Output() removeValueIndex = new EventEmitter();//this send positon of array
  languagesAll=[
  ];

  languagestranslated=[
  ];

  attributes : Attribute[]=[];
  nuevo: boolean = false;
  id:string;

  constructor(private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private Notify: SnotifyService,
              private attributeValuesService: Attribute_valueService
            ) {
            }

  ngOnInit() {
    if(this.languages){
      this.languagesAll = this.languages;
    }
/*     this.selected = 'es'; */
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  removeValue(){
    this.Notify.confirm('¿Seguro que quieres eliminar este valor?', {
      buttons:[
        {
          text : 'Eliminar', 
          action: toaster =>{
            this.attributeValuesService.deleteAttribute_value(this.value['_id']).subscribe(
              data => {
                //emit position to remove of array 
                this.removeValueIndex.emit(this.index)
              },
              error => this.Auth.handleError(error)
        
            )
            this.Notify.remove(toaster.id)
          }
        },
        {
          text : 'Cancelar', 
          action: toaster =>{
            this.Notify.remove(toaster.id)
          }
        }
      ]
    });
  }
}
