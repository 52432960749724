import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from "../../../../environments/environment";
import { TokenService } from '../../../services/token.service';
import { NgForm } from '@angular/forms';
import { Stock } from '../../stocks/interfaces/stocks';
import { Combination } from '../../combinations/interfaces/combination';
import { Price } from '../../prices/interfaces/prices';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  postCsvProducts(images){ 
    //let form_data = JSON.stringify(form);


    let headers = new HttpHeaders()
                      //.set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/csv/products', images, { headers });
  }

  importStocks(stocks: Stock[]){
    let stocks_data = JSON.stringify({stocks: stocks});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/import/stocks',stocks_data, { headers });
  }
  importCombinations(combinations: Combination[]){
    let combinations_data = JSON.stringify({combinations: combinations});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/import/combinations',combinations_data, { headers });
  }
  importPrices(prices: Price[]){
    let prices_data = JSON.stringify({prices: prices});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/import/prices',prices_data, { headers });
  }
}