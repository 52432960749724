import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { OrderService } from './services/order.service';
import { UtilService } from '../../services/utils.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OrdersComponent implements OnInit {
data:any;
datapie:any;
orders:any;
states:any;
statesAccesibles:any;
//pagination
pages:number;
endstart:number=0;
orderperpage:number=20;
currentorders:any;
  constructor(
    private auth: AuthService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private utils: UtilService,
    private Notify: SnotifyService,
    private orderService: OrderService,
  ) { 
    this.activatedRoute.data.subscribe(
      (response) => {
        if(response.data && response.data.orders){
            
          this.orders = response.data.orders.reverse()
          this.orders = [...this.orders, ...JSON.parse(localStorage.getItem('orders'))]
          this.pages = Math.round(this.orders.length/this.orderperpage)
          this.currentorders = this.orders.slice(0,this.orderperpage)
        }
        if(response.states && response.states.order_states){
          this.states = response.states.order_states.order_state;
          this.statesAccesibles = this.states.filter(state => {
            if(state.id == 4 || state.id == 6){
                return state;
            }
          })
          console.log(this.states); 
          console.log(this.statesAccesibles); 
/*           this.states.
          forEach((state, index) => {
           console.log(state.id);
           console.log(index);
           if (state.id != 2) {
               delete this.states[index];
            //this.states.splice(index, 1);
            }
  
          });
          console.log(this.states); */
          this.utils.processExposeName(this.states)
          this.assignStateOrder()
        }
      },
        error => this.auth.handleError(error)
    )
    this.datapie = {
      labels: ['0-50€','50-200€','200-500€','500-100€','1000-1500€'],
      datasets: [
          {
              data: [300, 220, 90,60,10],
              backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "red",
                  "black"
              ],
              hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "red",
                  "black"
              ]
          }]    
      };

    this.data = {
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
      datasets: [
          {
              label: 'Año 2017',
              backgroundColor: 'rgba(179,181,198,0.2)',
              borderColor: 'rgba(179,181,198,1)',
              pointBackgroundColor: 'rgba(179,181,198,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(179,181,198,1)',
              data: [65, 59, 90, 81, 56, 55, 40,90, 81, 56, 55, 40]
          },
          {
              label: 'Año 2018',
              backgroundColor: 'rgba(255,99,132,0.2)',
              borderColor: 'rgba(255,99,132,1)',
              pointBackgroundColor: 'rgba(255,99,132,1)',
              pointBorderColor: '#fff',
              pointHoverBackgroundColor: '#fff',
              pointHoverBorderColor: 'rgba(255,99,132,1)',
              data: [80, 48, 40, 19, 56, 27, 28, 48, 40, 79, 96, 27]
          }
      ]
  };
  }

  ngOnInit() {
  }
  assignStateOrder(){
      this.orders.forEach((order) => {
        order.fakecurrent_state = this.states.find(state => {
            return state.id == order.current_state;
        })
        order.fakecurrent_states = this.states;
      });
  }
  updateStateOrder(order){
    let idOrder = order.id;
    let idStates = order.fakecurrent_state.id;
    this.orderService.updateStateOrder(idOrder, idStates).subscribe(
        data => {
            this.Notify.success('Pedido actualizado correctamente', {timeout:1000})
        },
        error => this.auth.handleError(error)
    )
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  getPage(page){
    let end = (page+1)*this.orderperpage
    this.endstart = end-this.orderperpage
    this.currentorders = this.orders.slice(end-this.orderperpage, end)
  }
}
