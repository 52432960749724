import { Injectable } from '@angular/core';
import { TokenService } from '../../../services/token.service';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Product } from '../interfaces/product';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService, private translate: TranslateService) { }


  getProducts(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/products',{ headers });
  }

  getProductsExport(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/export/products',{ headers });
  }

  importProducts(products: Product[]){
    let products_data = JSON.stringify({products: products});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/import/products',products_data, { headers });
  }

  newProduct(product: Product){
    product["lang"] = this.translate.currentLang;
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/products',product_data, { headers });
  }

  updateProduct(product: Product, _id:string){
    product["lang"] = this.translate.currentLang;
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.put(environment.url_api+environment.api+'/products/'+_id,product_data, { headers });
  }

  getProduct(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/products/'+_id,{ headers });

  }
  deleteProduct(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/products/'+_id,{ headers });
  }

  getProductsPaginate(page=1, cant=10){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     return this.http.get(`${environment.url_api+environment.api}/products?page=${page}&cant=${cant}`,{ headers });
  }

  massiveProducts(data_action:Object){
    let action_data = JSON.stringify(data_action);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/massive/products', action_data,{headers});
  }
}
