import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { ConfigurationService } from '../../../services/configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ProductOptionsService {
  ///Deprecated in new version this service will be replaced by /src/services/utils.service.js


  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService, private configurationService: ConfigurationService) { }


  getProductOptions(){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_options/?io_format=JSON&ws_key=${this.configurationService.getWs_key()}&display=full`,{});
  }

  getProductOptionsValues(){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?io_format=JSON&ws_key=${this.configurationService.getWs_key()}&display=full`,{});
  }

  getProductOptionValuesByIdProductOption(id){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?display=full&io_format=JSON&ws_key=${this.configurationService.getWs_key()}&filter[id_attribute_group]=[${id}]`,{});
  }

  getProductOptionByIdProductOption(id){
     return this.http.get(`${this.configurationService.getDomain_ps()}api/product_option_values/?display=full&io_format=JSON&ws_key=${this.configurationService.getWs_key()}&filter[id]=[${id}]`,{});
  }
}
