import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BrandService  } from './services/brand.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Brand } from './interfaces/brand';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html'
})
export class BrandComponent implements OnInit {

  brand:Brand = {
    _id: "",
    name: "",
    active : true,
    user: "",
    image: ""
  }

  @Input() htmlentity: boolean;
  @Output() idChange = new EventEmitter();
  nuevo: boolean = false;
  id:string;

  constructor(private brandService : BrandService, 
              private tokenServide : TokenService, 
              private auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {
            
/*             if(!this.htmlentity){
              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.brandService.getBrand(this.id).subscribe(
                      data => {
                        console.log(data);
                        this.brand = data['brand'];
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
            } */
            }

  ngOnInit() {
            if(!this.htmlentity){
              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.brandService.getBrand(this.id).subscribe(
                      data => {
                        this.brand = data['brand'];
                      },
                      error => this.auth.handleError(error)
                    );
                  }
                }
              )
            }else{
                this.id = 'add';
            }
  }

  guardar(){
    if(this.id == 'add'){
      //insert
      this.brandService.newBrand(this.brand).subscribe(
        data=>{
          this.Notify.success("Marca creada",{timeout:1000});//0 = gets default timeout
          if(!this.htmlentity){
            setTimeout(() => {
                this._router.navigate(['/brands']);
            },2000);
            //this._router.navigate(['/brands',data['brand']._id])
          }else{
            this.idChange.emit(data);
          }
        },
        error => this.auth.handleError(error)
      )
    }else{
      //update
      this.brandService.updateBrand(this.brand, this.id).subscribe(
        data=>{
         // this._router.navigate(['/brands',data['brand']._id])
         this.Notify.info("Marca editada",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/brands']);
          },2000);
        },
        error => this.auth.handleError(error)
      )
    }
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/brand','add']);
    form.reset();

  }
}

