import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WondService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getWonds(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/wonds',{ headers });
  }

  newWond(form){

    let headers = new HttpHeaders()
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/wond',form, { headers: headers });
  }

  updateWond(data, id){
    let wond_data = JSON.stringify(data);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         
    
    return this.http.put(environment.url_api+environment.api+'/wonds/'+id,wond_data, { headers });
  }

}
