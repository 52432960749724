import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core'

import { AppComponent } from './app.component';
//import { NavbarComponent } from './components/navbar/navbar.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { LoginComponent } from './components/login/login.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { TokenService } from './services/token.service';
import { AfterLoginService } from './services/after-login.service';
import { BeforeLoginService } from './services/before-login.service';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { ProfileService } from './services/profile.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { NavbarComponent2 } from './components/shared/navbar/navbar.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { Catalog } from './components/catalogs/interfaces/catalog';
import { CatalogService } from './components/catalogs/services/catalog.service';
import { CatalogComponent } from './components/catalogs/catalog.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/products/product.component';
import { FileUploadModule } from 'primeng/components/fileupload/fileupload';
import { EditorModule } from "primeng/editor";
import {SidebarModule} from 'primeng/sidebar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import {OverlayPanelModule} from 'primeng/overlaypanel';
import {CalendarModule} from 'primeng/calendar';
import {InputSwitchModule} from 'primeng/inputswitch';
import {KeyFilterModule} from 'primeng/keyfilter';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/categories/category.component';
import { AttributesComponent } from './components/attributes/attributes.component';
import { AttributeComponent } from './components/attributes/attribute.component';
import { CategoryService } from './components/categories/services/category.service';
import {DropdownModule} from 'primeng/dropdown';
import { ImportsComponent } from './components/imports/imports.component';
import { ExportsComponent } from './components/exports/exports.component';

import {MultiSelectModule} from 'primeng/multiselect';
import {ChipsModule} from 'primeng/chips';
import {ChartModule} from 'primeng/chart';
import {GrowlModule} from 'primeng/growl';
import {DialogModule} from 'primeng/dialog';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { SupplierComponent } from './components/suppliers/supplier.component';
import { Slim } from './slim/slim.angular2';
import { OrdersComponent } from './components/orders/orders.component';
import { StocksComponent } from './components/stocks/stocks.component';
import { PricesComponent } from './components/prices/prices.component';
import { PriceComponent } from './components/prices/price.component';

import { BrandComponent } from './components/brands/brand.component';
import { StockComponent } from './components/stocks/stock.component';
import { StockService } from './components/stocks/services/stock.service';
import { BrandService } from './components/brands/services/brand.service';
import { ProductResolver } from './components/products/resolver/product-resolver.service';
import { BrandsResolver } from './components/products/resolver/brands-resolver.service';
import { AttributeValuesComponent } from './components/attributes/attribute_values/attribute_values.component';
import { Attribute_valueService } from './components/attributes/services/attribute_values.service';
import { ProfileResolver } from './components/profile/resolver/user-resolver.service';
import { IngresoComponent } from './components/winnings/ingreso/ingreso.component'
import { MyAccountComponent } from './components/myaccount/myaccount.component';
import { WinningsComponent } from './components/winnings/winnings.component'
import { UserRoleAdmin } from './services/user-role-admin';
import { SuperviseItemsComponent } from './components/admin_supervise/items/superviseItems.component'
import { CombinationComponent } from './components/combinations/combination.component'
import { CombinationsComponent } from './components/combinations/combinations.component'
import {PickListModule} from 'primeng/picklist';
import { ProductOptionsService } from './components/combinations/services/product_options.service'
import { ConfigurationComponent } from './components/configuration/configuration.component'
import { ConfigurationResolver } from './components/configuration/resolver/configuration-resolver.service'
import { ConfigurationService } from './services/configuration.service'
import { StocksProductComponent } from './components/stocks/product-stock/stocks-product.component'
import { CsvModule } from '@ctrl/ngx-csv';
import { PrestashopService } from './services/prestsahop.service'
import {AutoCompleteModule} from 'primeng/autocomplete';
import { CheckboxModule} from 'primeng/checkbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UtilService } from './services/utils.service';
import {WinningsService } from './components/winnings/services/winning.service';
import { OrderService } from './components/orders/services/order.service'
import { OrdersResolver } from './components/orders/resolver/orders-resolver.service'
import { StatesResolver } from './components/orders/resolver/states-resolver.service'
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import {  HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import localeEs from '@angular/common/locales/es'
import localeEsExtra from '@angular/common/locales/extra/es'
import { registerLocaleData } from '@angular/common';
import { SolicitudeService } from './components/myaccount/services/solicitudes.service';
import { GananciasResolver } from './components/myaccount/resolver/solicitudes-resolver.service';
import { AvisolegalComponent } from './components/avisolegal/avisolegal.component';
import { CarrierComponent } from './components/carrier/carrier.component'
import { CarrierService} from './components/carrier/services/carrier.service'
import { FooterComponent } from './components/shared/footer/footer.component';
import { WondComponent } from './components/wond/wond.component';
import {SelectButtonModule} from 'primeng/selectbutton';
import { ShopService } from './services/shop.service';
import { PaginationComponent } from './components/shared/pagination/pagination.component';
import { FilterTableComponent } from './components/shared/filter-table/filterTable.component';
import { DataSupplyService } from './services/data_supply.service';
import { TranslateInputComponent } from './components/shared/translate-input/translateInput.component';
import { PapaParseModule } from 'ngx-papaparse';


registerLocaleData(localeEs, 'es', localeEsExtra);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent2,
    SignupComponent,
    ProfileComponent,
    LoginComponent,
    RequestResetComponent,
    ResponseResetComponent,
    DashboardComponent,
    CatalogsComponent,
    CatalogComponent,
    ProductsComponent,
    ProductComponent,
    CategoriesComponent,
    CategoryComponent,
    AttributesComponent,
    AttributeComponent,
    ImportsComponent,
    ExportsComponent,
    SuppliersComponent,
    SupplierComponent,
    Slim,
    OrdersComponent,
    StocksComponent,
    PricesComponent,
    PriceComponent,
    StockComponent,
    BrandComponent,
    AttributeValuesComponent,
    IngresoComponent,
    MyAccountComponent,
    WinningsComponent,
    SuperviseItemsComponent,
    CombinationComponent,
    CombinationsComponent,
    ConfigurationComponent,
    StocksProductComponent,
    AvisolegalComponent,
    CarrierComponent,
    FooterComponent,
    PaginationComponent,
    TranslateInputComponent,
    WondComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    SnotifyModule,
    FileUploadModule,
    EditorModule,
    SidebarModule,
    BrowserAnimationsModule,
    //OverlayPanelModule,
    CalendarModule,
    InputSwitchModule,
    KeyFilterModule,
    DropdownModule,
    MultiSelectModule,
    ChipsModule,
    ChartModule,
    GrowlModule,
    DialogModule,
    ReactiveFormsModule,
    PickListModule,
    CsvModule,
    AutoCompleteModule,
    SelectButtonModule,
    PapaParseModule,
    CheckboxModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
  ],
  providers: [AuthService, TokenService, AfterLoginService, BeforeLoginService,
              { provide: 'SnotifyToastConfig', useValue: ToastDefaults},
              SnotifyService,
              ProfileService,
              CatalogService,
              CategoryService,
              StockService,
              BrandService,
              Attribute_valueService,
              ProductResolver,
              BrandsResolver,
              ProfileResolver,
              UserRoleAdmin,
              ProductOptionsService,
              ConfigurationService,
              ConfigurationResolver,
              PrestashopService,
              UtilService,
              WinningsService,
              OrderService,
              OrdersResolver,
              StatesResolver,
              TranslateService,
              SolicitudeService,
              GananciasResolver,
              CarrierService,
              ShopService,
              DataSupplyService,
/*               ConfigurationService, */
            ],
  bootstrap: [AppComponent]
})
export class AppModule { }
