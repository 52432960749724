import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PrestashopService } from './../../services/prestsahop.service'
import { AuthService } from './../../services/auth.service'
import { CombinationService } from '../combinations/services/combination.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { Price } from './interfaces/prices';
import { Combination } from './../combinations/interfaces/combination';
import { ProductOption, ProductOptionValues } from './../combinations/interfaces/product_options';
import { UtilService } from '../../services/utils.service';
import { PriceService } from './services/price.service';
import { ShopService } from '../../services/shop.service';

@Component({
  selector: 'price',
  templateUrl: './price.component.html',
  styleUrls: ['./prices.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PriceComponent implements OnInit {
  es: any
  priceForm: FormGroup;

  @Input() price: number;
  @Input() idproduct: string;
  @Input() attributes : ProductOption[]=[];
  @Input() attributeValues : ProductOptionValues[]=[];
  @Input() currencies: any = [];
  @Input() countries: any = [];
  @Input() groups: any = [];
  @Input() combinations: any[];
  combinationss
  @Input() isComponent: boolean = false;
  @Input() priceToModifyIndex : number;
  @Input() priceToModify: Price;

  @Output() canceled = new EventEmitter();
  @Output() priceSubmit = new EventEmitter();

  selectedReduction_type: number
  text: string;
  results: any[];

  type_discount = [{value:'amount',name:'Euro'},{value:'percentage',name:'%'}]
  type_tax = [{value:false,name:'Tax exclude.'},{value:true,name:'Tax include.'}]
  removedisable = false;
  selectCombination: any;
  shops: any;
  allshop:object = {id:0, name:'Todas las tiendas'}

  constructor(
    private auth: AuthService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private combinationService : CombinationService,
    private ps : PrestashopService,
    private priceService: PriceService,
    private shopService: ShopService,
    private utils: UtilService,
    private fb: FormBuilder 
  ) {
    this.priceForm = this.fb.group({
      'shop': new FormControl(this.allshop, Validators.compose([Validators.required, Validators.min(0)])),
      'currency': new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      'country': new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      'group': new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      'combination': new FormControl(0, Validators.compose([Validators.required, Validators.min(0)])),
      'customer': new FormControl(0),
      'dates': new FormControl('',validateDates()),
      'from_quantity': new FormControl(1,Validators.compose([Validators.required, Validators.min(0)])),
      'price': new FormControl({value:this.price, disabled:true}, Validators.compose([Validators.required, Validators.pattern("([0-9]*[.][0-9]{1,2})|([0-9]*)")])),
      'reduction': new FormControl(0.00, Validators.compose([Validators.required,Validators.min(0),Validators.pattern("([0-9]*[.][0-9]{1,2})|([0-9]*)")])),
      'reduction_type': new FormControl({value:'amount',name:'Euro'}),
      'reduction_tax': new FormControl({value:'true',name:'Tax include.'}),
    })

    this.es = {
        firstDayOfWeek: 1,
        dayNames: [ "Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado" ],
        dayNamesShort: [ "Dom","Lun","Mar","Mié","Jue","Vie","Sáb" ],
        dayNamesMin: [ "D","L","M","X","J","V","S" ],
        monthNames: [ "Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre" ],
        monthNamesShort: [ "Ene","Feb","Mar","Abr","May","Jun","Jul","Ago","Sep","Oct","Nov","Dic" ],
        today: 'Hoy',
        clear: 'Borrar'
    }
      this.shopService.getShops().subscribe(
        data => {
          this.shops = [this.allshop, ...data['shops']]
          this.utils.processExposeName(this.shops, 'name');
          this.patchShop();
        },
        error => this.auth.handleError(error)
      )

   }

  ngOnInit() {
    console.log(this.combinations)
    console.log(this.groups)
    //this.combinationss = this.combinations.map((item, index) => { return {...item, id:index}})
    this.combinationss = this.combinations
    if(this.priceToModify){
      this.patchShop()
      let currency = this.utils.findId(this.priceToModify.id_currency, this.currencies);
      let country = this.utils.findId(this.priceToModify.id_country, this.countries);
      let group = this.utils.findId(this.priceToModify.id_group, this.groups);
      let combination = this.utils.findId(this.priceToModify.id_product_attribute, this.combinations);
      console.log(combination)
      let dates:any = ''
      if(this.priceToModify.from !== '0000-00-00 00:00:00'){
        dates = [new Date(this.priceToModify.from), new Date(this.priceToModify.to)];
      }
      let from_quantity = this.priceToModify.from_quantity
      let price = this.priceToModify.price
      if(price != '-1'){
        this.removedisable = true
        this.clickBasePrice(this.removedisable)
      }
      let reduction:any = this.priceToModify.reduction_type === 'amount' ? this.priceToModify.reduction :  parseFloat(this.priceToModify.reduction) * 100
      let reduction_type = this.utils.findId(this.priceToModify.reduction_type, this.type_discount, 'value');
      let reduction_tax = this.utils.findId(JSON.parse(this.priceToModify.reduction_tax), this.type_tax, 'value');
      this.priceForm.patchValue({currency, country, group, combination, dates, from_quantity, price, reduction, reduction_tax, reduction_type})
      this.selectCombination = combination;
    }
  }

  patchShop(){
      let shop = 0  
      if(this.shops && this.shops.length > 0){
        shop = this.utils.findId(this.priceToModify.id_shop, this.shops);
        console.log(shop)
      }
      this.priceForm.patchValue({shop});
  }

  getCombinations(){
    return this.combinations
  }

/*   ngOnChanges(changes: SimpleChanges) {
    if (changes['combinations']) {
      if(this.combinations != this.combinationss){
        console.log('change');
        console.log(this.combinations)
        console.log(this.combinationss)
      }
    }
  } */

  cancelForm(){
    if(this.isComponent){
      this.canceled.emit(true)
    }
  }

  guardar(form: NgForm){
    let dates = this.priceForm.value.dates;
    let reduction_type = this.priceForm.value.reduction_type.value || 'amount'
    let reduction_tax = reduction_type != 'amount' ? true : this.priceForm.value.reduction_tax.value
    let reduction = reduction_type != 'amount' ? (this.priceForm.value.reduction/100) : this.priceForm.value.reduction
    let shop = this.priceForm.value.shop ? this.priceForm.value.shop.id : this.shops[0].id;
    let currency = this.priceForm.value.currency ? this.priceForm.value.currency.id : this.currencies[0].id;
    let country = this.priceForm.value.country ? this.priceForm.value.country.id : this.countries[0].id;
    let group = this.priceForm.value.group ? this.priceForm.value.group.id : this.groups[0].id;
    let combination = this.priceForm.value.combination ? this.priceForm.value.combination.id : this.combinations[0].id;
    let price = this.removedisable ? this.priceForm.value.price : "-1"
    let specific_price: Price = {
      _id:'',
      id_shop: shop,
      id_shop_group: '0',
      id_cart: '0',
      id_product: this.idproduct,
      id_product_attribute: combination,
      id_specific_price_rule: '0',
      id_currency: currency,
      id_country: country,
      id_group: group,
      id_customer: '0',
      price: price,//negative price
      from_quantity: this.priceForm.value.from_quantity,
      reduction: reduction,
      reduction_tax: reduction_tax,//amount percentage
      reduction_type: reduction_type,//amount percentage
      from : this.dateFormat(dates[0]),//done 
      to : this.dateFormat(dates[1])//done 
    }
    if(!this.priceToModify){
      console.log(specific_price)
      this.priceService.newPrice(specific_price).subscribe(
        data=>{
          this.Notify.success("Precio creado",{timeout:1000});//0 = gets default timeout
          this.priceSubmit.emit(data['price']);
          this.cancelForm()
        }
      )
    }else{
      console.log(specific_price)
      if(this.priceToModify._id){
        this.priceService.updatePrice(specific_price, this.priceToModify._id).subscribe(
          data=>{
            this.Notify.success("Precio creado",{timeout:1000});//0 = gets default timeout
            specific_price._id = this.priceToModify._id
            this.priceSubmit.emit(specific_price);
            this.cancelForm()
          }
        )
      }
    }
  }

  clickBasePrice(status){
    if(status){
      this.priceForm.controls.price.enable()
    }else{
      this.priceForm.controls.price.disable()
    }
  }
  dateFormat(date){
    if(!date){
      return '0000-00-00 00:00:00';
    }
    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2)
    let day = ("0" + (date.getDate())).slice(-2)
    let hours = ("0" + (date.getHours())).slice(-2)
    let minutes = ("0" + (date.getMinutes())).slice(-2)
    let seconds = ("0" + (date.getSeconds())).slice(-2)
    return year+'-'+month+'-'+day+' '+hours+':'+minutes+':'+seconds
  }
}
function validateDates(): ValidatorFn{
  return (control: AbstractControl): {[key: string]: boolean} | null => {
    if(control.value.length === 2 && typeof control.value[0] === "object" && typeof control.value[1] === "object" ){
      let date1 = new Date(control.value[0])
      let date2 = new Date(control.value[1])
      let now = new Date().setHours(0,0,0,0)
      if(date1.getTime() >= now && date1 < date2){
        return null 
      } 
    }else{
      if(control.value === ''){
        return null
      }
    }
    return {'validDates': false}
  }
}
