import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { ConfigurationService } from './configuration.service';
import { Combination } from './../components/combinations/interfaces/combination'
import { ProductOption, ProductOptionValues } from './../components/combinations/interfaces/product_options'
import {TranslateService} from '@ngx-translate/core'
import { DataSupplyService } from './data_supply.service';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  languages: any = []
  constructor(
    private http: HttpClient, 
    private configurationService : ConfigurationService,
    private translateService : TranslateService,
    private dataSupplyService: DataSupplyService,
  ) { 
    this.dataSupplyService.currentLanguages.subscribe(data => this.languages = data)
  }
  
  processExposeName(items, propertyAttach='name'){
    let lang = this.translateService.currentLang;
    items.forEach(item => {
      if(item.hasOwnProperty(propertyAttach)){
        if(typeof item[propertyAttach] == 'string'){
          item['exposeName'] = item[propertyAttach];
        }else if (typeof item[propertyAttach] == 'object'){
         let retrieve = item[propertyAttach].forEach(property => {
            if(property.hasOwnProperty('iso_code') && property.iso_code === lang){
              item['exposeName'] = property.value;
            }
          });
          if(!item['exposeName'] && typeof item[propertyAttach][0].value == 'string'){//not implemented multilang but change only number of array
            item['exposeName'] = item[propertyAttach][0].value;
          }
        }
      }
    });
    return items
  }

  processReturnName(item,index=true){
    let lang = this.translateService.currentLang;
        if(typeof item == 'string'){
          return item;
        }
        else if (typeof item == 'object'){
          let retrieve = item.find(property => {
            if((property.hasOwnProperty('iso_code') && property.iso_code === lang) || (property.hasOwnProperty('iso') && property.iso === lang)){
              return property.value;
            }
          });
          if(retrieve){
            return retrieve.value
          }
          if(index){
            let language = this.languages.find(l => l.iso == lang)
            if(language){
              return item[language.position-1].value;
            }
          }
        }
  }

  addAll(items, type){
    items.unshift({id:0,name:`All ${type}`});
    return this.processExposeName(items)
  }


  getAttributeValueOfCombination(c:Combination, attributes: ProductOption[], attribute_values: ProductOptionValues[]){
    let name = ""
    if(!c && !c.associations && !c.associations.product_option_values){
      return 'Not found'
    }
    c.associations.product_option_values.forEach(association_id => {
      if(!attribute_values || !attributes){
        return 'Not found';
      }
      let value = attribute_values.find((val) =>{
        return val.id == association_id
      })
      if(!value){
        name = 'Not found'
        return
      }
      let attribute = attributes.find((attr) => {
        return attr.id == value.id_attribute_group
      })
      if(!attribute){
        name = 'Not found'
        return
      }
      if(!name){
        name = this.processExposeName([attribute],'public_name')[0].exposeName +':'+ this.processExposeName([value])[0].exposeName;
      }else{
        name += ", "+this.processExposeName([attribute],'public_name')[0].exposeName+':'+ this.processExposeName([value])[0].exposeName;
      }
    });
    return name;
  }
      
  findId(valueId:any, whereFind, propertyName='id'){
    return whereFind.find(item => {
      return item[propertyName] == valueId;
    })
  }
  getProperty(item, propertyName){
      if(item && item.hasOwnProperty(propertyName)){
        return item[propertyName]
      }else{
        return 'Not found'
      }
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }
}
