import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { ProfileComponent } from './components/profile/profile.component';
import { RequestResetComponent } from './components/password/request-reset/request-reset.component';
import { ResponseResetComponent } from './components/password/response-reset/response-reset.component';
import { AppComponent } from './app.component';
import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';
import { UserRoleAdmin } from './services/user-role-admin';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { CatalogComponent } from './components/catalogs/catalog.component';
import { ProductsComponent } from './components/products/products.component';
import { ProductComponent } from './components/products/product.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { CategoryComponent } from './components/categories/category.component';
import { AttributesComponent } from './components/attributes/attributes.component';
import { AttributeComponent } from './components/attributes/attribute.component';
import { ImportsComponent } from './components/imports/imports.component';
import { ExportsComponent } from './components/exports/exports.component';
import { SuppliersComponent } from './components/suppliers/suppliers.component';
import { SupplierComponent } from './components/suppliers/supplier.component';
import { OrdersComponent } from './components/orders/orders.component';
import { StocksComponent } from './components/stocks/stocks.component';
import { StockComponent } from './components/stocks/stock.component';
import { PricesComponent } from './components/prices/prices.component';
import { PriceComponent } from './components/prices/price.component';
import {ProductResolver } from './components/products/resolver/product-resolver.service';
import { BrandsResolver } from './components/products/resolver/brands-resolver.service';
import { ProfileResolver } from './components/profile/resolver/user-resolver.service'
import { MyAccountComponent } from './components/myaccount/myaccount.component';
import { SuperviseItemsComponent } from './components/admin_supervise/items/superviseItems.component'
import { CombinationComponent } from './components/combinations/combination.component'
import { ConfigurationComponent } from './components/configuration/configuration.component'
import { ConfigurationResolver } from './components/configuration/resolver/configuration-resolver.service'
import { OrdersResolver } from './components/orders/resolver/orders-resolver.service';
import { StatesResolver } from './components/orders/resolver/states-resolver.service';
import { GananciasResolver } from './components/myaccount/resolver/solicitudes-resolver.service';
import { AvisolegalComponent } from './components/avisolegal/avisolegal.component';
import { WondComponent } from './components/wond/wond.component';

const appRoutes: Routes = [
  {   path:'',  redirectTo: 'dashboard', pathMatch: 'full'},
  //USER
  {   path:'login', component: LoginComponent,  canActivate: [BeforeLoginService]},
  {   path:'signup', component: SignupComponent, canActivate: [BeforeLoginService]},
  {   path:'profile', component: MyAccountComponent, canActivate: [AfterLoginService], resolve:{data:ProfileResolver, data_configuration: ConfigurationResolver, data_ganancias: GananciasResolver}},
  //PASSWORDS
  {   path:'request-password-reset', component: RequestResetComponent, canActivate: [BeforeLoginService]},
  {   path:'response-password-reset', component: ResponseResetComponent, canActivate: [BeforeLoginService]},
  //DASHBORAD
  {   path:'dashboard', component: DashboardComponent, canActivate: [AfterLoginService]},
  //CATALOGS
  {   path:'catalogs', component: CatalogsComponent ,canActivate: [AfterLoginService]},
  {   path:'catalogs/:id', component: CatalogComponent, canActivate: [AfterLoginService]},
  //PRODUCTS
  {   path:'products', component: ProductsComponent ,canActivate: [AfterLoginService]},
  {   path:'products/:id', component: ProductComponent, canActivate: [AfterLoginService], resolve:{data: ProductResolver, data_brands: BrandsResolver}},
  //CATEGORIES
  {   path:'categories', component: CategoriesComponent ,canActivate: [AfterLoginService]},
  {   path:'categories/:id', component: CategoryComponent, canActivate: [AfterLoginService]},
  //ATTRIBUTES
  {   path:'attributes', component: AttributesComponent ,canActivate: [AfterLoginService]},
  {   path:'attributes/:id', component: AttributeComponent, canActivate: [AfterLoginService]},
    //SUPPLIERS
    {   path:'suppliers', component: SuppliersComponent ,canActivate: [AfterLoginService]},
    {   path:'suppliers/:id', component: SupplierComponent, canActivate: [AfterLoginService]},
  //IMPORTS
  {   path:'imports', component: ImportsComponent ,canActivate: [AfterLoginService]},
  //EXPORTS
  {   path:'exports', component: ExportsComponent ,canActivate: [AfterLoginService]},
  //ORDERS
  {   path:'orders', component: OrdersComponent ,canActivate: [AfterLoginService], resolve:{data: OrdersResolver, states: StatesResolver}},
  //STOCKS
  {   path:'stocks', component: StocksComponent ,canActivate: [AfterLoginService]},
  {   path:'stocks/:id', component: StockComponent, canActivate: [AfterLoginService]},
  //PRICES
  {   path:'prices', component: PricesComponent ,canActivate: [AfterLoginService]},
  //SUPERVISE
  {   path:'supervise/:type', component: SuperviseItemsComponent ,canActivate: [AfterLoginService]},
  //COMBINATION
  {   path:'combinations/:id', component: CombinationComponent, canActivate: [AfterLoginService]},
  //AVISO LEGAL
  {   path:'aviso-legal', component: AvisolegalComponent, canActivate: [AfterLoginService]},
  //WOND
  {   path:'wond', component: WondComponent, canActivate: [AfterLoginService]},
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
