import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { Stock } from '../../stocks/interfaces/stocks';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }

  getStocsksExport(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/export/stocks',{ headers });
  }
  getCombinationsExport(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/export/combinations',{ headers });
  }
  getPricesExport(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/export/prices',{ headers });
  }
}
