import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarrierService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getZones(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         

     return this.http.get(environment.url_api+environment.api+'/zones',{ headers });
  }

/*   getBrands(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         

     return this.http.get(environment.url_api+environment.api+'/brands?page='+page,{ headers });
  }
*/
  getCarrier(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         

     return this.http.get(environment.url_api+environment.api+'/carrier',{ headers });
  }
  newCarrier(carrier){
    let carrier_data = JSON.stringify(carrier);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/carrier',carrier_data, { headers });
  }

  updateCarrier(data_carrier){
    let carrier_data = JSON.stringify(data_carrier);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         
    return this.http.get("http://grupok2pro.ecomm360.net/martketplace_utilities/carrier_product_1.php?datos="+carrier_data).subscribe(data => {
    });
    //return this.http.put(environment.url_api + 'martketplace_utilities/carrier_product_1.php', carrier_data, { headers });
  }

/*   getBrand(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/brands/'+_id,{ headers });

  }
  deleteBrand(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/brands/'+_id,{ headers });
  } */

}
