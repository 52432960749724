import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { StockService } from './services/stock.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Stock } from './interfaces/stocks';
import { SnotifyService } from 'ng-snotify';
import { Token } from '@angular/compiler';
import { ProductsService } from '../products/services/products.service';
import { Product } from '../products/interfaces/product';
import { ProductOptionsService } from '../combinations/services/product_options.service';
import { ProductOption, ProductOptionValues } from '../combinations/interfaces/product_options'
import { Combination } from '../combinations/interfaces/combination'
import { CombinationService } from '../combinations/services/combination.service';
import { UtilService } from '../../services/utils.service';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class StocksComponent implements OnInit {

  stocks: Stock[] = [];
  products: Product[] = [];
  combinaciones: Combination[] = [];
  attributes: ProductOption[] = [];
  attribute_values: ProductOptionValues[] = [];
  pages:number;

  constructor(
    private stockService: StockService, 
    private tokenService: TokenService, 
    private productService: ProductsService, 
    private combinationService: CombinationService, 
    private productOptionsService: ProductOptionsService, 
    private _router : Router, 
    private Auth : AuthService,
    private utils: UtilService,
  private Notify: SnotifyService) { }

  ngOnInit() {
    this.stockService.getStocks(1).subscribe(
      data => {
        
        this.stocks = data['stocks'].data;
        this.pages = data['stocks'].last_page;

      },
      error=> this.handleError(error)
    )
    this.productService.getProducts().subscribe(
      data => {
        this.products = data['products'];
      },
      error=> this.handleError(error)
    )
    //# If attributes of combinations
/*     this.combinationService.getProducts().subscribe(
      data => {
        this.products = data['products'];
      },
      error=> this.handleError(error)
    )
    this.productOptionsService.getProductOptions().subscribe(
      data => {
        this.attributes = data['product_options']
      },
      error => this.Auth.handleError(error)
    )
    this.productOptionsService.getProductOptionsValues().subscribe(
      data => {
        this.attribute_values = data['product_option_values']
      },
      error => this.Auth.handleError(error)
    ) */
  }

  getPageStock(page){
    this.stockService.getStocks(page+1).subscribe(
      data => {
        this.stocks = data['stocks'].data;
        this.pages = data['stocks'].last_page;
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteStock(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este stock ?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.stockService.deleteStock(_id).subscribe(
            data => {
            this.stocks.splice(index,1);
            },
            error => this.Auth.handleError(error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }
  
  getNameProduct(product_id){
    if(product_id){
      let product = this.products.find(product => {
        return product._id == product_id 
      })
      if(!product || !product.name){
        return ''
      }
      return this.utils.processReturnName(product.name,false)
    }
    return ''
  }
//# If attributes of combinations 
/*   getAttributeValueOfCombination(c:Combination){
    let name = ""
    c.associations.product_option_values.forEach(association_id => {
      let value = this.attribute_values.find((val) =>{
        return val.id == association_id
      })
      if(!value){
        name = 'Not found'
        return
      }
      let attribute = this.attributes.find((attr) => {
        return attr.id == value.id_attribute_group
      })
      if(!attribute){
        name = 'Not found'
        return
      }
      if(!name){
        name = this.contieneIdiomas(attribute.public_name) +':'+ this.contieneIdiomas(value.name);
      }else{
        name += ", "+this.contieneIdiomas(attribute.public_name)+':'+ this.contieneIdiomas(value.name);
      }
    });
    return name;
  }

  contieneIdiomas(val){
    if(typeof val == "object"){
      return val[0].value;
    }else{
      return val;
    }
  } */
}