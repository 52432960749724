import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSupplyService } from '../../../services/data_supply.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent2 implements OnInit {

  public name = null;
  public loggedIn:boolean;
  public isAdmin:boolean;
  public languages =  [{name:'Spanish', value:'es'},
            {name:'English', value:'en'},
            {name:'Chinese', value:'zh'}]
  selectLang = {name:'Spanish', value:'es'};
    
  constructor(private Auth: AuthService,
              private router: Router, 
              private Token : TokenService,
              private translate: TranslateService,
              private dataSupplyService: DataSupplyService,
            ) { }

  ngOnInit() {
    this.Auth.authStatus.subscribe(value=>this.loggedIn = value);
    this.Auth.nameStatus.subscribe(value=>this.name = value);
    this.Auth.adminStatus.subscribe(value=>this.isAdmin = value)
    this.dataSupplyService.currentLanguages.subscribe(languages => {
      this.languages = languages.map(language => {
        return {'name': language.name, 'value':language.iso}
      })
    })


    //this.name = this.getUser();
    }

    logout(event: MouseEvent){
      event.preventDefault();
      this.Auth.unauthenticated();
      this.router.navigateByUrl('/login');
    }
  
    getUser(){
      return localStorage.getItem('name');
    }

    changeLanguage(){
      this.translate.use(this.selectLang.value);
      this.translate.currentLang = this.selectLang.value;
    }



}
