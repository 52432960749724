import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { Router } from '@angular/router';
import { TokenService } from '../../../services/token.service';
import { TranslateService } from '@ngx-translate/core';
import { trigger } from '@angular/animations';
import { UtilService } from '../../../services/utils.service';
import { DataSupplyService } from '../../../services/data_supply.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'translateInput',
  templateUrl: './translateInput.component.html',
  styleUrls: ['./translateInput.component.css']
})
export class TranslateInputComponent implements OnInit {

  languages: any;
  selectedLanguage:any
  value: any = [];
  @Input() left: boolean = false;
  @Input() input: boolean = false;
  @Input() dropdown: boolean = false;
  @Input() id: string = '';
  @Input() name: string = '';
  @Input() placeholder: string = '';
  @Output() triggerValue = new EventEmitter();
  @Input() form: FormGroup;
  @Input() validationName: string;
  @Input() loadValue: any = [];
  count: number = 0 

  constructor(private Auth: AuthService,
              private router: Router,
              private utils : UtilService,
              private translate: TranslateService,
              private dataSupplyService : DataSupplyService,
              private fb: FormBuilder,
            ) {

             }

  ngOnInit() {
    this.dataSupplyService.currentLanguages.subscribe(languages => {
      this.languages = languages; 
      this.formatValue()
    })
  }

  formatValue(){
    this.selectedLanguage = this.translate.currentLang
    if(this.count < 2 && this.languages){
      this.load();
      this.count += 1 
    }
  }

  changeInput(e = null, i){
    console.log(this.form)
/*       if(this.form && !this.form.){
      this.triggerValue.emit(undefined);
    } */
    if(e){
      this.value[i] = e.htmlValue
    }
    let value = []
    this.languages.forEach(language => {
      let v = this.value[language.position]
      if(v){
        value.push({iso_code: language.iso, value:v})
      }
    })
    console.log(value)
    this.triggerValue.emit(value);
  }

  load(){
    if(typeof this.loadValue === 'string'){
      this.value = ['',...this.languages.map((l) => this.loadValue)]
    }else{
      console.log(this.loadValue)
      let value = Object.keys(this.loadValue).map(key => {
        let val = this.loadValue[key]
        let lang = this.languages.find((l) => l.iso == val.iso_code)
        return {'position': lang.position, 'value':val.value}
      });
      value.sort(function(a,b){
        if(a.position < b.position)
          return -1 
        if(a.position > b.position)
          return 1
        return 0
      })
      this.value =['',...value.map((val) => val.value)] 
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    //detect change attributeValues change to load attributes selected
    if (changes['loadValue'] && this.loadValue) {
      if(this.count < 2 && this.languages){
        this.load();
        this.count += 1 
      }
    }
  }
  
}
