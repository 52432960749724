import { Component, OnInit } from '@angular/core';
//import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {


  public errors = [];
  public form = {
    email:null,
    name:null,
    lastname:null,
    empresa:null,
    password:null,
    password_confirmation:null,
    validated: false
  }



  constructor(private Auth: AuthService,
  private Token: TokenService,
private router: Router) { }


  
  onSubmit(){
    /* return this.http.post(environment.url_api+environment.api+'/signup',this.form).subscribe(
      data => console.log(data),
      error => this.handleError(error)
    ); */

    this.Auth.signup(this.form).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );

  }

  handleError(error){
    this.errors = error.error.errors;
  }

  handleResponse(data){
    this.Auth.authenticated(data);
    this.router.navigateByUrl('/dashboard');
  }
  ngOnInit(): void {
    const body = document.getElementsByTagName('body')[0];
/*     body.classList.remove('skin-blue');
    body.classList.remove('hold-transition');
    body.classList.remove('sidebar-mini');
    body.classList.add('login-page');

 
    body.classList.add('hold-transition');
    body.classList.add('register-page'); */
  }

  ngOnDestroy(): void {
   const body = document.getElementsByTagName('body')[0];
/*    body.classList.remove('hold-transition');
   body.classList.remove('register-page');
   body.classList.add('skin-blue');
    body.classList.add('hold-transition');
    body.classList.add('sidebar-mini'); */
  }

}
