import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Message } from 'primeng/components/common/message';
import { OrderService } from '../orders/services/order.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public name = null;
  data: any = [];

  data2: any;
    
  msgs: Message[];
  constructor(private orderservice: OrderService) {

    this.orderservice.getOrders().subscribe(data=>{
        if(data){
            let orders = JSON.parse(localStorage.getItem('orders'))
            data['orders'] = [...orders, ...data['orders']] 
            var coun1= 0;
            var coun2 = 0;
            var coun3 = 0;
            var pagado = 0;
            var enviado = 0;
            var entrega = 0;
            data['orders'].forEach(element => {
                
                if(element.total_paid <= 50) { coun1 ++;}
                if(element.total_paid > 50 && element.total_paid <= 200){coun2++;}
                if(element.total_paid > 200 && element.total_paid <= 1500){coun3++;}
                if(element.current_state == 2){pagado++;}
                if(element.current_state == 4){ enviado++;}
                if(element.current_state == 5){entrega++;}
            });
            this.data = {
                labels: ['0-50€','50-200€','200-1500€'],
                datasets: [
                    {
                        data: [coun1,coun2,coun3],
                        backgroundColor: [
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"
                        ],
                        hoverBackgroundColor: [
                            "#FF6384",
                            "#36A2EB",
                            "#FFCE56"
                        ],
                        
                    }]    
                };

                this.data2 = {
                    labels: ["Pagado","Enviado","Entrgado"],
                    datasets: [
                        {
                            data: [pagado,enviado,entrega],
                            backgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ],
                            hoverBackgroundColor: [
                                "#FF6384",
                                "#36A2EB",
                                "#FFCE56"
                            ],
                        }
                    ]
                }
        }
    });   

  }







  selectData(event) {
      this.msgs = [];
      this.msgs.push({severity: 'info', summary: 'Value Selected', 'detail': this.data2.datasets[event.element._datasetIndex].data[event.element._index]});
  }




  ngOnInit() {

    this.name = this.getUser;
  }

  getUser(){
    return localStorage.getItem('name');
  }
  
  

}
