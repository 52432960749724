import { Component, OnInit } from '@angular/core';
import { Category } from './interfaces/category';
import { CategoryService } from './services/category.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';
import { empty } from 'rxjs';
import { INTERNAL_BROWSER_DYNAMIC_PLATFORM_PROVIDERS } from '@angular/platform-browser-dynamic/src/platform_providers';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  category: any = {}
  categories: any = [];
  selectedCategory: any;
  nuevo: boolean = false;
  id:string;

  constructor(private categoryService : CategoryService, 
              private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.categoryService.getCategory(this.id).subscribe(
                      data => {
                        this.category = data['category'];
                        this.selectCategory()
                      },
                      error => this.Auth.handleError(error)
                    );
                  }else{
                    this.category['active'] = 0;
                  }
                }
              )
            }

  ngOnInit() {

    this.categoryService.getCategoriesAll().subscribe(
      data => {
        this.categories = data['categories'];
        this.selectCategory()
      },
      error=> this.Auth.handleError(error)
    )

  }

  changeActiveStatus(){
    this.category.active = !this.category.active;
  }

  guardar(){
      if(this.selectedCategory !== undefined && this.selectedCategory !== null){
        this.category.parent_id = this.selectedCategory._id;
      }
      if(this.selectedCategory == null){
        this.category.parent_id = "";
      }
    if(this.id == 'add'){
      //insert
      this.categoryService.newCategory(this.category).subscribe(
        data=>{
          this.Notify.success("Categoría creada",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/categories']);
          },2000);

          //this._router.navigate(['/catalogs',data['catalog']._id])
        },
        error => this.Auth.handleError(error)
      )
    }else{
      //update
      this.categoryService.updateCategory(this.category, this.id).subscribe(
        data=>{
         // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Categoría editada",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/categories']);
          },2000);
        },
        error => this.Auth.handleError(error)
      )
    }
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/category','add']);
    form.reset();

  }
   selectCategory(){
     if(this.categories && this.categories.length > 0){
       if(this.category && this.category.parent_id){
         this.selectedCategory = this.categories.find((category) => category._id == this.category.parent_id)
       }
     }
   }
}
