import { Component, OnInit } from '@angular/core';
import { CategoryService } from './services/category.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { Category } from './interfaces/category';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit {


  categories: Category[] = [];

  pages:number;
  page:number = 1;
  cant:number = 10;
  constructor(private categoryService: CategoryService, 
    private tokenService : TokenService, 
    private _router : Router, 
    private Auth : AuthService,
    private Notify: SnotifyService) { }

  ngOnInit() {
    this.getCategories()
  } 

  deleteCategory(_id : string, index : number){
    this.Notify.confirm('¿Seguro que quieres eliminar esta categoría?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{
          this.categoryService.deleteCategory(_id).subscribe(
            data => {
            this.categories.splice(index,1);
            },
            error => this.Auth.handleError(error)
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
          }
        }
      ]
    });
  }
  getCategories(){
    let filters = [];
/*     if(this.productFilter != {}){
      filters = Object.keys(this.productFilter).map(key => {
        if(this.productFilter[key]){
          return {'name': key, 'value':this.productFilter[key]}
        }
      });
    } */
    this.categoryService.getCategories(this.page, this.cant).subscribe(
      data => {
        this.categories = data['data'];
        this.pages = data['last_page'];
        this.page = data['current_page'];
      },
      error=> this.Auth.handleError(error)
    )
  }

  getPage(page){ 
    this.page = page;
    this.getCategories()
  }

  changeCant(cant){
    this.cant = parseInt(cant)
    this.getCategories();
  }

}
