import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SolicitudeService } from '../services/solicitudes.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GananciasResolver implements Resolve<Object> {
    constructor(
        private solicitudeService: SolicitudeService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {

        return  this.solicitudeService.getGanancias();
    }
}