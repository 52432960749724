import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment} from "../../../../environments/environment";
import { TokenService } from '../../../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class WinningsService {

  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }

  sendMail(){
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/retrieve/send','', { headers });
  }
  ask(){
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.get(environment.url_api+environment.api+'/retrieve/ask', { headers });
  }

  getGanancias(){
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.get(environment.url_api+environment.api+'/ganancias', { headers });
  }
}