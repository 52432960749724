import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as fake from 'faker';
@Injectable()
export class TokenService {

  private iss =
    [
      environment.url_api + environment.api + '/login',
      environment.url_api + environment.api + '/signup'
    ]

  constructor(private http: HttpClient) {

/*     let orders = [] 
    let total_money = 0 
    while(total_money < 200000){
      let sdfsdf = { 
        "id": "89139", 
        "id_customer": "56594",  
        "current_state": total_money > 100000 ? "4" : "5", "module": "bankwire", "date_add": "2018-10-02 09:20:31", "date_upd": "2018-10-09 13:43:55", "shipping_number": "", 
        "id_shop_group": "1", "id_shop": "1", "secure_key": "b253e35412b29af2e5c62eaa94fbe2ce", "payment": "Transferencia bancaria","reference": "P4092"+fake.random.number(),
          "total_paid": fake.commerce.price(), 
            "customer_name": fake.fake("{{name.firstName}} {{name.lastName}} {{name.lastName}}"), "customer_address": { "id_address": "61651", "id_country": "6", "id_state": "325", "id_customer": "56594", 
            "id_manufacturer": "0", "id_supplier": "0", "id_warehouse": "0", "alias": "My address", "company": "", "lastname": "apellidos", "firstname": "Oscar", 
            "date_add": "2018-0"+fake.random.number()+"-25 09:11:29", "date_upd": "2018-10-02 09:20:28", "active": "1", "deleted": "0", "country": fake.address.country(), 
            "state": "Cádiz", "iso_code": "ES", "state_iso": "ES-CA" }, "order_rows": { "326741": { "id": "326741", "product_id": "71742", "product_attribute_id":
            "87313", "product_quantity": "1", "product_name": "Test Sin combinaciones - Sabor : Limón", "product_reference": "demo_1", "product_ean13": "", "product_upc":
            "", "product_price": "52.000000", "unit_price_tax_incl": "46.800000", "unit_price_tax_excl": "46.800000" } } }
            orders.push(sdfsdf);
            total_money += parseFloat(sdfsdf.total_paid);
    }
    localStorage.setItem('orders', JSON.stringify(orders))
    localStorage.setItem('total', JSON.stringify(total_money))
    console.log(fake.fake("{{name.lastName}}, {{name.firstName}} {{name.suffix}}")); */
  }

  handle(token) {
    this.set(token);
  }

  set(token) {
    localStorage.setItem('token', token.access_token);
    localStorage.setItem('user', JSON.stringify(token.user));
    // console.log(token.user);
  }

  getName() {
    if (localStorage.getItem('user')) {
      return JSON.parse(localStorage.getItem('user')).name;
    }
    return ""
  }

  getRole() {
    if (localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')).role) {
      return JSON.parse(localStorage.getItem('user')).role;
    }
    return "user"
  }

  isAdmin() {
    return this.getRole() == "admin"
  }

  get() {
    return localStorage.getItem('token');
  }
  remove() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  isValid() {
    const token = this.get();

    if (this.get()) {
      const payload = this.payload(token);
      //console.log(payload);
      if (payload) {

        return this.iss.indexOf(payload.iss) < 0 ? false : true;
      }
    }
    return false;
  }

  payload(token) {

    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }

  checkTokenValid() {

    const tokenToCheck = this.get();

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('authorization', 'Bearer ' + tokenToCheck);

    return this.http.post(environment.url_api + environment.api + '/checkToken', null, { headers: headers })

  }


}
