import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { Configuration } from './interfaces/configuration';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'configuration',
  templateUrl: './configuration.component.html',
})
export class ConfigurationComponent implements OnInit {

  configurationForm: FormGroup;
  configuration: Configuration;
  @Output() cancel = new EventEmitter();


  
  constructor(
    private Auth : AuthService,
    private tokenService: TokenService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private http : HttpClient,
    private fb: FormBuilder 
  ) { 
    this.configurationForm = this.fb.group({
      'domain_ps': new FormControl('', Validators.compose([Validators.required, Validators.pattern('(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})')])),
      'ws_key': new FormControl('', Validators.compose([Validators.required])),
    })
    this.activatedRoute.data.subscribe(
      (response) => {
        if(response && response.data_configuration && response.data_configuration[0]){
          let configuration = response.data_configuration[0]
          if(configuration && configuration.ws_key && configuration.domain_ps){
            this.configurationForm.patchValue({ws_key: configuration.ws_key, domain_ps: configuration.domain_ps})
          }
        }
      },
      error => this.handleError(error)
    )
  }

  ngOnInit() {
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  guardar(form){
  }
  
  cancelForm(){
    this.cancel.emit(true)
  }
  
  testConnection(){
    console.log(`${this.configurationForm.value.domain_ps}api/?io_format=JSON&ws_key=${this.configurationForm.value.ws_key}`);
    this.http.get(`${this.configurationForm.value.domain_ps}api/?io_format=JSON&ws_key=${this.configurationForm.value.ws_key}`).subscribe(
      data => {
        this.Notify.success("Los datos son validos",{timeout: 1000})
      },
      error => {
        this.Notify.error("Los datos no son validos",{timeout: 1000})
      }
    ) 
  }
  
}
