import { Component, OnInit } from '@angular/core';
import { Product } from './interfaces/product';
import { ProductsService } from './services/products.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import {LightboxModule} from 'primeng/lightbox';
import { CategoryService } from '../categories/services/category.service';
import { Category } from '../categories/interfaces/category';
import { UtilService } from '../../services/utils.service';
import { environment } from '../../../environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  products: Product[] = [];
  stocks: any = {};
  categories : any[]=[];
  pages:number;
  page:number = 1;
  cant:number = 10;
  selectedProducts: string[] = [];
  languages =  [
   {name: 'Eliminar', value:'eliminar'},
   {name: 'Activar', value:'activar'},
   {name: 'Descativar', value:'desactivar'},
   {name: 'Cambiar estado', value:'status'},
   {name: 'Cambiar categoria', value:'category'},
  ]
  selectActionProducts: Object = {name: 'Cambiar categoria', value:'category'};
  selectedCategory: any;
  constructor(private productService: ProductsService, 
    private _router : Router, 
    private Auth : AuthService,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private utils: UtilService,
    private categoryService : CategoryService,
    private translateService: TranslateService,
  ) {

 

   }

  ngOnInit() {

    this.getProducts();
    this.categoryService.getCategoriesAll().subscribe(
      data => {
        this.categories = data['categories'];
        this.utils.processExposeName(this.categories);
      },
      error=> this.Auth.handleError(error)
    )
  } 

  deleteProduct(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este producto?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{
            //this._router.navigateByUrl('/login'),
            this.productService.deleteProduct(_id).subscribe(
              data => {
              this.products.splice(index,1);
              },
              error => this.Auth.handleError(error)
            )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }

/*   getPage(page){
    this.getProducts(page+1)
  } */

  getProducts(){
    this.productService.getProductsPaginate(this.page, this.cant).subscribe(
      data => {
        this.products = data['products'];
        this.stocks = data['stocks'];
        this.pages = data['last_page'];
      },
      error=> this.Auth.handleError(error)
    )
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  duplicateProduct(product : Product){
  }

  convertImage(image_cover){

    let images = [];
    images.push({source: image_cover, thumbnail: image_cover, title:'Sopranos 1'});
    return images;
    
  }

  getNameCategory(category_id){
    if(category_id){
      let category = this.categories.find(category => {
        return category._id == category_id 
      })
      if(!category || !category.exposeName){
        return ''
      }
      return category.exposeName
    }
    return ''
  }

  getLinkRewrite(name,id_ps){
    return environment.shop_url+"/"+id_ps+'-'+name.toLowerCase().replace(/[\s-]+/, ' ').split(' ').join('-')+'.html';
  }

  actionProducts(){
    if(this.selectedProducts.length > 0 && (this.selectActionProducts && this.selectActionProducts['value'])){
      let action_data = {action: this.selectActionProducts['value'], product_ids: this.selectedProducts}
      if(this.selectActionProducts && this.selectActionProducts['value'] == 'category'){
        if(this.selectedCategory && this.selectedCategory._id){
          action_data['category'] = this.selectedCategory._id
        }
      }
      console.log(action_data);
      this.productService.massiveProducts(action_data).subscribe(
        data => {
          if(data['products'] && data['action'] != 'eliminar'){
            data['products'].forEach(product => {
              let assoProduct = this.products.findIndex((productOld) => product._id === productOld._id);
              this.products[assoProduct] = product;
            });
          }else if(data['action'] === 'eliminar' && data['products_ids']){
            this.getProducts();
          }
          this.Notify.info(this.translateService.instant('Accion realizada satisfactoriamente'),{timeout:1000});//0 = gets default timeout
          this.selectedProducts = [];
        },
        err =>  this.Auth.handleError(err) 
      )
    }
  }

  getPage(page){ 
    this.page = page;
    this.getProducts()
  }

  changeCant(cant){
    this.cant = parseInt(cant)
    this.getProducts();
  }

}
