import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges} from '@angular/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
/* import { CombinationService } from './services/combination.service'; */
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { Combination } from './interfaces/combination';
import { Attribute, AttributeValue, AttributeValues} from '../attributes/interfaces/attribute';
import {AttributeService} from '../attributes/services/attribute.service';
import { ProductOptionsService } from './services/product_options.service';
import { ProductOption, ProductOptionValues } from './interfaces/product_options';
import { CombinationService } from '../combinations/services/combination.service';
import { UtilService } from '../../services/utils.service';


@Component({
  selector: 'combination',
  templateUrl: './combination.component.html'
})
export class CombinationComponent implements OnInit {


  selected:any;
  
  attribute: Attribute = {
    _id:"",
    name : [],
    categories : "",
    values :[]
  }

  values_attr: string[] = [];


  values:any;
  nuevo: boolean = false;
  id:string;
  enablePickList: boolean = false;
  showCombinationForm: boolean = false;
  sourceAttributeValues: ProductOptionValues[] = [];
    
  targetAttributeValues: ProductOptionValues[] = [];

  selectedAttribute: number
  fakeArrary = [];
  combinationForm: FormGroup;
  @Output() combinationsSubmit = new EventEmitter();//this send positon of array
  @Input() type: string;
  @Input() attributes : ProductOption[]=[];
  @Input() attributeValues : ProductOptionValues[]=[];
  combination: Combination = {
      _id: "",
      id_product: "",
      reference: "",
      ean13: "",
      upc: "",
      wholesale_price: "0",
      price: "0",
      weight: "0",
      unit_price: "0",
      minimal_quantity: "0",
      default_on: "",
      availability_date: "",
      associations:{
        product_option_values:[]
      }
    }
  @Input() combinationToModify: Combination;
  @Input() priceProduct: number;
  @Input() idproduct: string;
  @Input() isComponent: boolean = false;
  @Output() canceled = new EventEmitter();
  @Input() combinationToModifyIndex : number;

  

  constructor(private attributeService : AttributeService, 
              private tokenService : TokenService, 
              private Auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService,
              private productOptionsService: ProductOptionsService,
              private combinationService : CombinationService,
              private utils: UtilService,
              private fb: FormBuilder,
            ) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                  }else{
                    this.productOptionsService.getProductOptions().subscribe(
                      data => {
                        this.attributes = data['product_options']
                        this.utils.processExposeName(this.attributes, 'public_name');
                      },
                      error => this.Auth.handleError(error)
                    )
                  }
                }
              )
              this.combinationForm = this.fb.group({
                'selectedAttribute': new FormControl(''),
                'reference': new FormControl(''),
                'ean13': new FormControl(''),
                'upc': new FormControl(''),
                'wholesale_price': new FormControl(0, Validators.compose([Validators.required])),
                'impact_price': new FormControl('none', Validators.compose([Validators.required])),
                'impact_weight': new FormControl('none', Validators.compose([Validators.required])),
                'impact_unit_price': new FormControl('none', Validators.compose([Validators.required])),
                'price': new FormControl(0, Validators.compose([Validators.required])),
                'weight': new FormControl(0, Validators.compose([Validators.required])),
                'unit_price': new FormControl(0, Validators.compose([Validators.required])),
                'minimum_quantity': new FormControl(1, Validators.compose([Validators.required])),
                'availability_date': new FormControl(''),
              })
            }

  ngOnInit() {
    this.selected = 'es';
    if(this.combinationToModify){
      this.id = 'update'
      this.combination = this.combinationToModify
      this.combinationForm.patchValue({...this.combination})
      let combinationImpacts = {}
      let propertytochanges = ['price', 'weight', 'unit_price']
      propertytochanges.forEach((property) => {
      switch (true) {
        case this.combination[property] < 0 :
          combinationImpacts[property] = this.combination[property] * -1
          combinationImpacts[`impact_${property}`] = property == 'price' ? 'decrease' : 'reduction' 
          break;

        case this.combination[property] > 0 :
          combinationImpacts[`impact_${property}`] = 'increase' 
          break;
      
        default:
          combinationImpacts[`impact_${property}`] = 'none' 
          break;
      }
      })
      this.combinationForm.patchValue({...combinationImpacts})
    }
    if(this.combinationToModify && this.targetAttributeValues.length < 1){
      this.combinationToModify.associations.product_option_values.forEach(pov => {
        if(this.attributeValues){
          this.targetAttributeValues.push(this.attributeValues.find((val) => {return pov == val.id}))
        }
      });
    }
    if(!this.attributes || !this.attributeValues){
      //this.cancelForm();
    }
  }
  test(e){
  }

  ngOnChanges(changes: SimpleChanges) {
    //detect change attributeValues change to load attributes selected
    if (changes['attributeValues'] && this.targetAttributeValues.length < 1) {
      this.targetAttributeValues = []
      if(this.combinationToModify){
        this.combinationToModify.associations.product_option_values.forEach(pov => {
          if(this.attributeValues){
            let attr = this.attributeValues.find((val) => val.id == pov)
            this.targetAttributeValues.push(attr)
          }
        });
      }
    }
  }

  customTrackBy(index: number, obj: any): any {
    return index;
  }

  guardar(){
      let combination: Combination = {...this.combination};//clone because copy modified == generateStocks()

      combination.reference = this.combinationForm.value.reference
      combination.ean13 = this.combinationForm.value.ean13
      combination.upc = this.combinationForm.value.upc
      combination.wholesale_price = this.combinationForm.value.wholesale_price
      let propertytochanges = ['price', 'weight', 'unit_price']
      propertytochanges.forEach((value) => {
        let val = this.combinationForm.value[`impact_${value}`]
        switch (true) {
          case val == 'increase':
            combination[value] = this.combinationForm.value[value]
            break;
          case val == 'decrease' || val == 'reduction':
            combination[value] = 0 - this.combinationForm.value[value]
            break;
          default:
            combination[value] = 0
            break;
        }
      })
      combination.availability_date = this.combinationForm.value.availability_date
      combination.associations.product_option_values = [];
      this.targetAttributeValues.forEach(attributevalue => {
        combination.associations.product_option_values.push(attributevalue.id);
      })
    if(this.id == 'add' || !this.combination._id){
      if(!combination.availability_date){
        combination.availability_date = "0000-00-00"
      }
      if(this.idproduct){
        combination.id_product = this.idproduct
        this.combinationService.newCombination(combination).subscribe(
          data=>{
            this.Notify.success("Combinacion creada",{timeout:1000});//0 = gets default timeout
            combination._id = data['combination']._id
            this.combination = combination
            this.combinationsSubmit.emit(this.combination)
            this.cancelForm()
          },
          error=>{
            if(error.status === 403 && error.error && error.error.errors && error.error.errors === "Exist combination with this reference"){
              this.Notify.warning('La referencia esta ya registrada prueba otra !', {timeout: 2000}) 
              this.combinationForm.controls.reference.reset()
            }else{
              this.Auth.handleError(error)
            }
          }
        )
      }else{
        this.combination = combination
        this.combinationsSubmit.emit(this.combination)
        this.cancelForm()
      }
    }else{
      if(this.idproduct){
        combination.id_product = this.idproduct
        this.combinationService.updateCombination(combination, combination._id).subscribe(
          data=>{
            this.Notify.info("Combinacion editada",{timeout:1000});//0 = gets default timeout
            this.combination = combination
            this.combinationsSubmit.emit(this.combination)
            this.cancelForm()
          },
          error=>{
            if(error.status === 403 && error.error && error.error.errors && error.error.errors === "Exist combination with this reference"){
              this.Notify.warning('La referencia esta ya registrada prueba otra !', {timeout: 2000}) 
              this.combinationForm.controls.reference.reset()
            }else{
              this.Auth.handleError(error)
            }
          }
        )
      }else{
        this.combination = combination
        this.combinationsSubmit.emit(this.combination)
        this.cancelForm()
      }
    }
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/attribute','add']);
    form.reset();
  }

  getCorrectAtributesValues(){
    this.sourceAttributeValues = []
    let correctAttribute = this.attributes.find(attirbute => {
      return attirbute.id == this.combinationForm.value.selectedAttribute.id;
    })
    this.sourceAttributeValues = this.attributeValues.filter((value) => value.id_attribute_group == correctAttribute.id)
  }

  getNameAttributeByID(id){
    let attribute = this.attributes.find((attribute) => {
      return id == attribute.id
    })
    if(attribute){
      return this.contieneIdiomas(attribute.public_name);
    }
  }

  checkIfAttributedSelected(){
     this.enablePickList = this.targetAttributeValues.some(attribute_value => {
      if(this.combinationForm.value.selectedAttribute.id == attribute_value.id_attribute_group){
        return true
      }
    })
  }

  moveItemsToOriginalArray(event){
    if(event.items.length == 1){
      let item = event.items[0]
      let position = this.sourceAttributeValues.indexOf(item)
      if(this.combinationForm.value.selectedAttribute.id == item.id_attribute_group){// entra si el valor es del mismo attributo y portanto hay que pasarlo del fake array a source
        if(position == -1){
          this.sourceAttributeValues.push(item)//si no esta en source se añade porque son del mismo attributo
        }
      }else if( this.combinationForm.value.selectedAttribute.id != item.id_attribute_group){// entra si no es del mismo attributo y por tanto hay que validar si ha sido puesto en source  
        if( position != -1 ){
          this.sourceAttributeValues.splice(position, 1)//si esta en source se quita porque son de distinto attributo
        }
      }
      this.fakeArrary = [];
    }
  }

  movedToTarget(event){
    if(event.items.length == 1){
      let item = event.items[0]
      let count = this.targetAttributeValues.filter((attribute_value) => {
        return item.id_attribute_group == attribute_value.id_attribute_group
      })
      if(count.length > 1){
        let position = this.targetAttributeValues.indexOf(item)
        if(position != -1){
          this.targetAttributeValues.splice(position, 1)//si esta en source se quita porque son de distonto attributo
        }
      }
    }
  }
  cancelForm(){
    if(this.isComponent){
      this.combinationForm.reset()
      this.combinationForm.patchValue({wholesale_price: 0, impact_price: 'none', impact_weight: 'none', impact_unit_price: 'none', price: 0, weight: 0, unit_price: 0, minimum_quantity: 1 })
      this.canceled.emit(true)
    }
  }

  contieneIdiomas(val){
    if(typeof val == "object"){
      return val[0].value;
    }else{
      return val;
    }
  }

}
