import { Component, ViewEncapsulation } from '@angular/core';
import { ConfigurationService } from './services/configuration.service';
import { TranslateService } from '@ngx-translate/core';
import { DataSupplyService } from './services/data_supply.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';
  languages:any;
  constructor(
              private configurationService: ConfigurationService,
              private translateService: TranslateService,
              private dataSupplyService: DataSupplyService,
              ){
    this.dataSupplyService.currentLanguages.subscribe(languages => this.languages = languages)
    this.configurationService.configurationInit()
    this.translateService.setDefaultLang('es');
    this.translateService.currentLang = 'es';
    this.dataSupplyService.loadLanguages()
    //this.translateService.use("es");
  }
}
