import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { AttributeValues } from '../interfaces/attribute';

@Injectable({
  providedIn: 'root'
})
export class Attribute_valueService {


  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getAttribute_values(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     return this.http.get(environment.url_api+environment.api+'/attribute_values?page='+page,{ headers });
  }

  newAttribute_value(attribute_value:AttributeValues){
    let attribute_value_data = JSON.stringify(attribute_value);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/attribute_values',attribute_value_data, { headers });
  }

  updateAttribute_value(attribute_value:AttributeValues, _id:string){
    let attribute_value_data = JSON.stringify(attribute_value);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/attribute_values/'+_id,attribute_value_data, { headers });
  }

  getAttribute_value(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/attribute_values/'+_id,{ headers });

  }
  deleteAttribute_value(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/attribute_values/'+_id,{ headers });
  }

}
