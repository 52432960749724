import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { Combination } from '../interfaces/combination';

@Injectable({
  providedIn: 'root'
})
export class CombinationService {


  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getCombinations(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     return this.http.get(environment.url_api+environment.api+'/combinations?page='+page,{ headers });
  }

  newCombination(combination:Combination){
    let combination_data = JSON.stringify(combination);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/combinations',combination_data, { headers });
  }

  newCombinations(combination:Combination[]){
    let combination_data = JSON.stringify({'combinations': combination});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/combinations',combination_data, { headers });
  }

  updateCombination(combination:Combination, _id:string){
    let combination_data = JSON.stringify(combination);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/combinations/'+_id,combination_data, { headers });
  }

  getCombination(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/combinations/'+_id,{ headers });

  }
  deleteCombination(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/combinations/'+_id,{ headers });
  }

}
