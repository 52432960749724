import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductsService } from '../services/products.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProductResolver implements Resolve<Object> {
    constructor(
        private productsService: ProductsService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {

        if(route.params['id'] && route.params['id'] != 'add')
            return  this.productsService.getProduct(route.params['id']);
    }
}