import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthService } from './../../services/auth.service';
import { TokenService } from './../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { WinningsService} from './services/winning.service'

@Component({
  selector: 'my-winnings',
  templateUrl: './winnings.component.html',
})
export class WinningsComponent implements OnInit {

  showIngreso = false;
  ask:boolean = false;
  ganancias:any;
  constructor(
    private Auth : AuthService,
    private tokenService: TokenService, 
    private winningService: WinningsService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
  ) { 
    this.canSend()
  }

  ngOnInit() {
  }
  canSend(){
    this.winningService.ask().subscribe(
      data =>{
        this.ask = data['result'];
      },
      error => {
        this.Auth.handleError(error);
      }
    )
    this.winningService.getGanancias().subscribe(
      data =>{
        this.ganancias = data;
        this.ganancias['ganancia_pedidos'] = JSON.parse(localStorage.getItem('total'))
        this.ganancias['ganancia_actual'] = parseFloat(JSON.parse(localStorage.getItem('total'))) - this.ganancias['ganancia_pagada']
      },
      error => {
        this.Auth.handleError(error);
      }
    )
  }

  sendMail(){
    this.winningService.sendMail().subscribe(
      data => {
        this.Notify.success('Solicitado el ingreso de ganancias',{timeout:1000})
        this.canSend()
      },
      error => {
        this.Auth.handleError(error)
      }
    );
    //toaster
    this.canSend();
  }
  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }
  
}
