import { Injectable } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from '../../../services/token.service';
import { environment } from '../../../../environments/environment';
import { Price } from '../interfaces/prices';

@Injectable({
  providedIn: 'root'
})
export class PriceService {


  data = null;
  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getPrices(page){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     return this.http.get(environment.url_api+environment.api+'/prices?page='+page,{ headers });
  }

  newPrice(price:Price){
    let price_data = JSON.stringify(price);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/prices',price_data, { headers });
  }

  newPrices(price:Price[]){
    let price_data = JSON.stringify({'prices': price});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/prices',price_data, { headers });
  }

  updatePrice(price:Price, _id:string){
    let price_data = JSON.stringify(price);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/prices/'+_id,price_data, { headers });
  }

  getPrice(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/prices/'+_id,{ headers });

  }
  deletePrice(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/prices/'+_id,{ headers });
  }

}
