import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import {AuthService} from '../../services/auth.service'
import { SolicitudeService } from './services/solicitudes.service';

@Component({
  selector: 'myaccount',
  templateUrl: './myaccount.component.html'
})
export class MyAccountComponent implements OnInit {

  showIngreso = false;
  solicitudes:any;
  states: any = [
    {name: 'Pagado', value: 1},
    {name: 'No Pagado', value: 0}
  ]
  public isAdmin:boolean;
  constructor(
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
    private Auth: AuthService,
    private solicitudeService: SolicitudeService,
  ) { 
    this.activatedRoute.data.subscribe(
      (response) => {
        if(response.data_ganancias && response.data_ganancias.solicitudes){
          this.solicitudes = response.data_ganancias.solicitudes
          this.formatSolicitudes();
        }
      },
        error => this.Auth.handleError(error)
    )
  }

  ngOnInit() {
    this.Auth.adminStatus.subscribe(value=>this.isAdmin = value)
  }

  updateGanancia(solicitude){
    let price = solicitude.fakeprice;
    let state = solicitude.fakestate.value;
    this.solicitudeService.updateGanancia({price, state}, solicitude._id).subscribe(
        data => {
            this.Notify.success('Solicitud actualizada correctamente', {timeout:1000})
            this.solicitudeService.getGanancias().subscribe(
              data => {
                this.solicitudes = data['solicitudes'];
                this.formatSolicitudes();
              },
              error => this.Auth.handleError(error)
            )
        },
        error => this.Auth.handleError(error)
    )
  }

  formatSolicitudes(){
      this.solicitudes.forEach((solicitude) => {
        solicitude.fakestate = this.states.find(state => {
            return state.value === solicitude.state;
        })
        solicitude.fakeprice = solicitude.price;
      });
  }

}
