import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import { TokenService } from './token.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthService {

  constructor(
    private http: HttpClient, 
    private Token: TokenService,
    private _router : Router
  ) { }

  private loggedIn = new BehaviorSubject<boolean>(this.Token.loggedIn());
  private token = null;
  authStatus = this.loggedIn.asObservable();

  changeAuthStatus(value:boolean){
    this.loggedIn.next(value);//emitir un nuevo valor en el flujo de datos que maneja el Observable
  }


  
  private name = new BehaviorSubject<String>(this.Token.getName());
 
  nameStatus = this.name.asObservable();

  changeNameStatus(value:String){
    this.name.next(value);//emitir un nuevo valor en el flujo de datos que maneja el Observable
  }


  private isadmin = new BehaviorSubject<boolean>(this.Token.isAdmin());
 
  adminStatus = this.isadmin.asObservable();

  changeAdminStatus(value:boolean){
    this.isadmin.next(value);//emitir un nuevo valor en el flujo de datos que maneja el Observable
  } 

  signup(data){
    return this.http.post(environment.url_api+environment.api+'/signup',data)
  }
  login(data){
    return this.http.post(environment.url_api+environment.api+'/login',data)
  }

  sendPasswordResetLink(data){
    return this.http.post(environment.url_api+environment.api+'/sendPasswordResetLink',data)
  }

  changePassword(data){
    return this.http.post(environment.url_api+environment.api+'/resetPassword',data)
  }

  getUserId(){
    if(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))._id){
      return JSON.parse(localStorage.getItem('user'))._id;
    }else{
        this.changeAuthStatus(false);
        this.Token.remove();
        this._router.navigateByUrl('login');
    }
  }

 getProfile(data){
   this.token = this.Token.get();

   let headers = new HttpHeaders()
                    .set('Content-Type', 'application/json')             
                    .set('authorization', 'Bearer ' + this.token);
                    
    return this.http.post(environment.url_api+environment.api+'/me',data,{ headers: headers })
 }


  updateProfile(profile, _id:string){
    let profile_data = JSON.stringify(profile);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.put(environment.url_api+environment.api+'/users/'+_id,profile_data, { headers });
  }

  getProfiles(){
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.get(environment.url_api+environment.api+'/users',{ headers });
  }

  getProfilesPaginates(page=1){
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.get(environment.url_api+environment.api+'/users?page='+page,{ headers });
  }

  authenticated(token){
    if(token && token.user && token.access_token) {
      this.Token.set(token)
      this.changeAuthStatus(this.Token.loggedIn())
      this.changeNameStatus(token.user)
      this.changeAdminStatus(this.Token.isAdmin())
    }
  }
  unauthenticated(){
      this.Token.remove()
      this.changeAuthStatus(this.Token.loggedIn())
      this.changeNameStatus("")
      this.changeAdminStatus(this.Token.isAdmin())
  }

  handleError(error){
      if(error.error[0]){
        this.changeAuthStatus(false);
        this.Token.remove();
        this._router.navigateByUrl('login');
      }
  }

}
