import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Configuration } from '../components/configuration/interfaces/configuration';

@Injectable()
export class ConfigurationService {


  configuration: Configuration = {
      _id: "",
      domain_ps: "",
      ws_key: "",
  };

  constructor(
    private http: HttpClient, 
    private Token: TokenService,
    private _router : Router,
    private Auth : AuthService,
  ) { }

  getConfiguration(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     return this.http.get(environment.url_api+environment.api+'/configuration',{ headers });
  }
  
  configurationInit(){
    this.getConfiguration().subscribe(
      data =>{
        if(data && data[0]){
          let configuration = data[0]
          if(configuration && configuration.ws_key && configuration.domain_ps){
            localStorage.setItem('configuration', JSON.stringify(configuration))
            this.configuration = configuration
          }
        }
      },
      error =>{
        this.Auth.handleError(error)
      }
    )
  }
  getDomain_ps(){
    return this.configuration.domain_ps || "http://grupok2pro.ecomm360.net/" 
  }
  getWs_key(){
    return this.configuration.ws_key || "U3XYFMB44JNRHBS6H5S55RNXZZVK6Y7B"
  }
}