import { ConfigurationService } from './../../../services/configuration.service';
import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ConfigurationResolver implements Resolve<Object> {
    constructor(
        private configurationService: ConfigurationService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return  this.configurationService.getConfiguration()
    }
}
