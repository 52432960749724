import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Product } from '../products/interfaces/product';
import { Combination } from '../combinations/interfaces/combination';
import { WondService } from './services/wond.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { environment } from '../../../environments/environment';
import * as jQuery from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wond',
  templateUrl: './wond.component.html',
  styleUrls: ['./wond.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class WondComponent implements OnInit {



  wond = {
    //_id: "",
    nacional_empresa: "",
    nacional_seguimiento: "",
    internacional_paqueteria_empresa: "",
    internacional_paqueteria_seguimiento: "",
    internacional_contenedor_empresa: "",
    internacional_contenedor_seguimiento: "",
    csvproducts:null,
    csvcombinations:null,
    user_id:""

  }
  resourceName: string = '';
  products: Product[] = [];
  combinations: Combination[] = [];
  productsFile: File;
  combinationsFile: File;
  nacional: boolean = true;
  wonds: any =[]
  isAdmin:boolean;
  status: any = [
    {id: 1, name: 'Enviado', value: this.translateService.instant('Enviado')},
    {id: 2, name: 'Creado', value: this.translateService.instant('Creado')},
    {id: 3, name: 'Recivido', value: this.translateService.instant('Recivido') },
    {id: 4, name: 'Confirmado', value: this.translateService.instant('Confirmado')},
    {id: 5, name: 'Confirmado con errores', value: this.translateService.instant('Confirmado con errores')}
  ]//Enviado / recibido / confirmado / confirmado con errores)
  url: string = environment.url_api
  envio:boolean = false;
  constructor(private wondService: WondService,
    private auth : AuthService,
    private notify : SnotifyService,
    private translateService: TranslateService,
    private http : HttpClient
    ) {

      this.getWonds();
     }

  ngOnInit() {
    this.auth.adminStatus.subscribe(value=>this.isAdmin = value)
  }

  onFileChangedProducts(event) {
    this.productsFile= <File> event.target.files[0];
    // const uploadData = new FormData();
    // uploadData.append('myFile', this.selectedFile,this.selectedFile.name);
    // uploadData.append('name', '32564264236');
    // return this.http.post('http://grupok-2.com/martketplace_utilities/wond.php',uploadData).subscribe(data => console.log(data));

  }
  onFileChangedCombinations(event) {

    this.combinationsFile= <File> event.target.files[0];
  }

  isNacional(){
    if(this.wond.nacional_empresa || this.wond.nacional_seguimiento){
      return true
    }else {
      return false
    }
  }
  isInternacional(){
    if(this.wond.internacional_contenedor_empresa || this.wond.internacional_contenedor_seguimiento || this.wond.internacional_paqueteria_empresa || this.wond.internacional_paqueteria_seguimiento){
      return true
    }else {
      return false
    }
  }
  
  guardar(form: NgForm){
 
    this.wond.user_id = this.auth.getUserId();
    console.log(this.wond);
    console.log(form.value);

    const uploadData = new FormData();
    uploadData.append('csvproducts', this.productsFile,this.productsFile.name);
    uploadData.append('csvcombinations', this.combinationsFile,this.combinationsFile.name);
    uploadData.append('nacional_empresa', this.wond.nacional_empresa);
    uploadData.append('nacional_seguimiento', this.wond.nacional_seguimiento);
    uploadData.append('internacional_paqueteria_empresa', this.wond.internacional_paqueteria_empresa);
    uploadData.append('internacional_paqueteria_seguimiento', this.wond.internacional_paqueteria_seguimiento);
    uploadData.append('internacional_contenedor_empresa', this.wond.internacional_contenedor_empresa);
    uploadData.append('internacional_contenedor_seguimiento', this.wond.internacional_contenedor_seguimiento);
    uploadData.append('user_id', this.wond.user_id);

/*     this.http.post('http://grupok-2.com/martketplace_utilities/wond.php',uploadData).subscribe(data => console.log(data)); */
    this.wondService.newWond(uploadData).subscribe(
      data=>{
        this.notify.success('Wond creado',{timeout:1000})
        this.getWonds();
        this.envio = true
        jQuery('html,body').animate({ scrollTop: 0 }, 'slow');
      },
      error => this.auth.handleError(error)
    ) 
  }

  updateWond(wond){
    wond.status = wond.fakestatus.name
    this.wondService.updateWond(wond, wond._id).subscribe(
      data => {
        this.notify.info('Wond actualizado',{timeout:1000})
        this.getWonds();
      },
      error => this.auth.handleError(error)
    )
  }

  getWonds(){
    this.wondService.getWonds().subscribe(
      data => {
        this.wonds = data['wonds'];
        this.wonds.forEach(wond => {
          wond.fakestatus = this.status.find(statu => {
            return statu.name === wond.status
          })
        });
      },
      error => this.auth.handleError(error)
    )
  }
}

