import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OrderService } from '../services/order.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class StatesResolver implements Resolve<Object> {
    constructor(
        private orderService: OrderService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {

        return  this.orderService.getStates();
    }
}