import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, IterableDiffers } from '@angular/core';
import { StockService } from '../services/stock.service';
import { TokenService } from '../../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { Stock } from '../interfaces/stocks';
import { SnotifyService } from 'ng-snotify';
import { Token } from '@angular/compiler';
import { Combination } from '../../combinations/interfaces/combination';
import { ProductOption, ProductOptionValues } from '../../combinations/interfaces/product_options'
import { ProductOptionsService } from '../../combinations/services/product_options.service';
import { defineDirective } from '../../../../../node_modules/@angular/core/src/render3';
import { UtilService } from '../../../services/utils.service';
import { ShopService } from '../../../services/shop.service';

@Component({
  selector: 'stocks-product',
  templateUrl: './stocks-product.component.html',
  encapsulation: ViewEncapsulation.Emulated
})
export class StocksProductComponent implements OnInit {

  pages:number;
  @Input() idproduct: string;
  @Input() combinations: Combination[];
  @Input() stocks: Stock[]
  @Output() stocksgenerated = new EventEmitter();
  @Input() isCreate: boolean = false;
  @Input() attributeValues: any;
  @Input() attributes : ProductOption[]=[];
  shops: any;
  allshop:object = {id:0, name:'Todas las tiendas'}
  selectShops: any={};

  constructor(
    private stockService: StockService, 
    private shopService : ShopService, 
    private _router : Router, 
    private utils : UtilService, 
    private Auth : AuthService,
    private Notify: SnotifyService
  ) {
      this.shopService.getShops().subscribe(
        data => {
          this.shops = [this.allshop, ...data['shops']]
          this.utils.processExposeName(this.shops, 'name');
          this.formatSelectShop();
        },
        error => this.Auth.handleError(error)
      )
  }

  ngOnInit() {
/*     console.log(this.stocks);
    this.stocks.map((item,i) => {
      if(item && item.id_shop){
        this.selectShops[i] = this.shops.find((shop) => shop.id == item.id_shop)
        return item 
      }else{
        return {...item, id_shop:0}
      }
    }) */
  }

  getAttributeValueOfCombination(stock, i){
    let name = ""
    
    let c 
    if(!stock._id || !stock.combination_id){
      c = this.combinations[i]
    }else{
      c = this.combinations.find((comb) => {
        return comb._id == stock.combination_id 
      })
    }
    if(!c || !c.associations){
      return "Stock Simple"
    }
    return this.utils.getAttributeValueOfCombination(c, this.attributes, this.attributeValues) ;
  }

  update(stock){
    this.stockService.updateStock(stock, stock._id).subscribe(
      data=>{
        this.Notify.info("Stock editado",{timeout:1000});//0 = gets default timeout
      },
      error => this.Auth.handleError(error)
    )
  }

  crear(stock, i){
    this.stockService.newStock(stock).subscribe(
      data=>{
        this.Notify.success("Stock creado",{timeout:1000});//0 = gets default timeout
        stock._id = data['stock']._id
      },
      error => this.Auth.handleError(error)
    )
  }
  
  getPageStock(page){
    this.stockService.getStocks(page+1).subscribe(
      data => {
        this.stocks = data['stocks'].data;
        this.pages = data['stocks'].last_page;
      },
      error=> this.Auth.handleError(error)
    )
  }

  numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteStock(_id : string, index : number){
    this.Notify.confirm('¿Seguro que quieres eliminar este stock ?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.stockService.deleteStock(_id).subscribe(
            data => {
            this.stocks.splice(index,1);
            },
            error => this.Auth.handleError(error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }

  changeStore(e, stock, i){
    if(e.value && e.value.id && e.value.id_shop_group){
      this.stocks[i] = {...stock, id_shop: e.value.id, id_shop_group:e.value.id_shop_group}
      console.log(this.stocks)
    }
  }
  formatSelectShop(){
    if(this.shops && this.shops.length > 0 && this.stocks && this.stocks.length > 0){
      this.selectShops = this.stocks.map((stock, i) => {
        if(stock && (stock.id_shop || stock.id_shop === 0)){
          return this.shops.find((shop) => shop.id == stock.id_shop)  
        }else{
          this.stocks[i] = {...stock, id_shop:0}
          return this.allshop
        }
      })
    }
  }
}