import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { TokenService } from './token.service';
import { AuthService } from './auth.service';

@Injectable()
export class UserRoleAdmin implements CanActivate{
    public tokenValid = false;
    public logged ;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
   /**
    * Checkear si el token ha expirado o no y si esta el token y viene de integrafy
    */
        return this.Token.isAdmin()
    }
  
    constructor(private Token: TokenService, private Auth : AuthService,private router: Router) { }

}

