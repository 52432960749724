import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
/* import { BrandService  } from './services/brand.service'; */
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
/* import { Brand } from './interfaces/brand'; */
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'avisolegal',
  templateUrl: './avisolegal.component.html'
})
export class AvisolegalComponent implements OnInit {

  @Input() htmlentity: boolean;
  @Output() idChange = new EventEmitter();
  nuevo: boolean = false;
  id:string;

  constructor( 
              private tokenServide : TokenService, 
              private auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {
            
/*             if(!this.htmlentity){
              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.brandService.getBrand(this.id).subscribe(
                      data => {
                        console.log(data);
                        this.brand = data['brand'];
                      },
                      error => console.error(error)
                    );
                  }
                }
              )
            } */
            }

  ngOnInit() {
            if(!this.htmlentity){
            }else{
            }
  }

  guardar(){
  }
}

