import { Component, OnInit, Input, IterableDiffers } from '@angular/core';
import { Combination } from './interfaces/combination';
import { AttributeService } from '../attributes/services/attribute.service';
/* import { CombinationService } from './services/combination.service'; */
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { SnotifyService } from 'ng-snotify';
import { ProductOption, ProductOptionValues } from './interfaces/product_options'
import { ProductOptionsService } from '../combinations/services/product_options.service';
import { CombinationService } from './services/combination.service';
import { nextTick } from 'q';
import { UtilService } from '../../services/utils.service';

@Component({
  selector: 'combinations',
  templateUrl: './combinations.component.html',
})
export class CombinationsComponent implements OnInit {


/*   combination: Combination =   */
  @Input() combinations: Combination[];
  @Input() price: number;
  @Input() idproduct: string;
  @Input() attributes : ProductOption[]=[];
  @Input() attributeValues : ProductOptionValues[]=[];
  attribute_values: any;

  pages : number ;
  selectedCombinationIndex: number = 0; 
  iterableDiffer
  constructor(private attributeService: AttributeService, 
              private tokenService : TokenService, 
              private _router : Router, 
              private Auth : AuthService,
              private _iterableDiffers : IterableDiffers,
              private productOptionsService : ProductOptionsService,
              private combinationService : CombinationService,
              private utils : UtilService,
              private Notify: SnotifyService) {
               }

  ngOnInit() {
    if(!this.attributes){
      this.productOptionsService.getProductOptions().subscribe(
        data => {
          this.attributes = data['product_options']
          this.utils.processExposeName(this.attributes, 'public_name');
        },
        error => this.Auth.handleError(error)
      )
    }
    if(!this.attributeValues){
      this.productOptionsService.getProductOptionsValues().subscribe(
        data => {
          this.attribute_values = data['product_option_values']
        },
        error => this.Auth.handleError(error)
      )
    }else{
      this.attribute_values = this.attributeValues 
    }
  }
  
  save(e){
  }


 numbersToArray(n: number): any[] {
    return Array(n);
  }

  create(combination){
    this.combinations.push(combination)
  }
  update(data){
    if(data._id){
/*       let combination = this.combinations.find(comb => {
        return comb._id = data._id;
      }) */
/*       let position = this.combinations.lastIndexOf(combination); */
      let position = this.combinations.findIndex(item => {
        return item._id == data._id;
      })
      this.combinations[position] = data;
    }else{
     this.combinations[this.selectedCombinationIndex] = data; 
    }
  }
  
  getNameAttributeByID(id){
    let attribute = this.attributes.find((attribute) => {
      return id == attribute.id
    })
    if(attribute){
      return this.contieneIdiomas(attribute.public_name);
    }
  }
  getAttributeValueOfCombination(c:Combination){
    let name = ""
    c.associations.product_option_values.forEach(association_id => {
      if(!this.attribute_values || !this.attributes){
        return 'Name not found';
      }
      let value = this.attribute_values.find((val) =>{
        return val.id == association_id
      })
      if(!value){
        name = 'Not found'
        return
      }
      let attribute = this.attributes.find((attr) => {
        return attr.id == value.id_attribute_group
      })
      if(!attribute){
        name = 'Not found'
        return
      }
      if(!name){
        name = this.contieneIdiomas(attribute.public_name) +':'+ this.contieneIdiomas(value.name);
      }else{
        name += ", "+this.contieneIdiomas(attribute.public_name)+':'+ this.contieneIdiomas(value.name);
      }
    });
    return name;
  }

  updateCombination(_id: string, index:number){


  }

  deleteCombination(_id : string, index : number){
    if(!_id){
      this.combinations.splice(index, 1)
    }else{
      this.Notify.confirm('¿Seguro que quieres eliminar esta combination ?', {
        buttons:[
          {text : 'Eliminar', 
          action: toaster =>{
            this.combinationService.deleteCombination(_id).subscribe(
              data => {
                this.combinations.splice(index, 1)
                this.Notify.remove(toaster.id)
              },
              error => this.Auth.handleError(error)
            )
              }
          },
          {text : 'Cancelar', 
          action: toaster =>{
              this.Notify.remove(toaster.id)
              }
          }
        ]
      });
    }
  }
  
  contieneIdiomas(val){
    if(typeof val == "object"){
      return val[0].value;
    }else{
      return val;
    }
  }

}
