import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MethodSend } from './interfaces/methodSend';
import { NgForm } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  profile = {
    _id: "",
    name: "",
    lastname: "",
    empresa: "",
    email: "",
    fullname: "",
    image: "",
    accountBank: "",
    emailNotifications: 1,
    newsletter: 1,
    methodsend: "",
    address: ""
  }

  methodssend: MethodSend[]= [];
  selectedMethodSend: MethodSend;
  methodsend: MethodSend = {
    name : "",
  }

  slimOptions = {
    ratio: '1:1',
    download: true,
    filterSharpen: '10',
    willRemove: this.imageWillBeRemoved.bind(this),
    didConfirm: this.imageDidBeConfirmed.bind(this),
    instantEdit: true,
    label: '<i class="fa fa-picture-o" aria-hidden="true"></i>',
    buttonConfirmLabel: 'Confirmar',
    buttonConfirmTitle: 'Confirmar',
    buttonCancelLabel: 'Cancelar',
    buttonCancelTitle: 'Cancelar',
    buttonEditTitle: 'Editar',
    buttonRemoveTitle: 'Eliminar',
    buttonDownloadTitle: 'Descargar',
    buttonRotateTitle: 'Rotar',
    buttonUploadTitle: 'Subir',
    statusImageTooSmall:'Imagen demasiado pequeña por favor inténtelo con otra'
  };
  
  constructor(
    private Auth : AuthService,
    private tokenService: TokenService, 
    private _router : Router,
    private activatedRoute : ActivatedRoute,
    private Notify: SnotifyService,
  ) { 
    this.methodssend = [{name:"CORREOS"}, {name:"MRW"}]
    this.activatedRoute.data.subscribe(
      (response) => {
        if(response.data && response.data.user){
          this.profile = response.data.user
          if(this.profile.methodsend){
            this.selectedMethodSend = {name: this.profile.methodsend};
          }
        }
      },
      error => this.handleError(error)
    )
  }

  ngOnInit() {
    this.slimOptions['initialImage'] = this.profile.image
  }

  getProfile(){
    this.Auth.getProfile('test').subscribe(
      error => this.handleError(error)
    );
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

  guardar(form: NgForm){
  if(this.selectedMethodSend){
    this.profile.methodsend = this.selectedMethodSend.name
  }else{
    this.profile.methodsend = "" 
  }
  if(this.profile.methodsend != 'MRW'){
    this.profile.address = "";
  }
  if(!this.fn_ValidateIBAN(this.profile.accountBank)){
    this.Notify.warning("IBAN Incorrecto",{timeout:1000});
    return;
  }
    this.Auth.updateProfile(this.profile, this.profile._id).subscribe(
      data=>{
        this.Notify.info("Perfil editado",{timeout:1000});//0 = gets default timeout
        setTimeout(() => {
          this._router.navigate(['/dashboard']);
        },2000);
      },
      error=>this.Auth.handleError(error)
    )
  }
  
  //process events images

  changeImage(e:any){//save image in base64
    e.preventDefault()
    let files = e.target.files || e.dataTransfer.files;
    let file = files[0];
    if(file){
      let reader = new FileReader;
      reader.onloadend = ()=>{
        if(reader.result.match('data:image')){
          this.profile.image = reader.result//save base64
        }
      }
      reader.readAsDataURL(file);
    }
  }
  imageWillBeRemoved(data, that, remove) {
    if (window.confirm("Are you sure?")) {
      remove();
      this.profile.image = "";
    }
  }
  imageDidBeConfirmed(data, that) {
    this.profile.image = that.dataBase64.output.image
  }

  //Validacion de IBANs
  fn_ValidateIBAN(IBAN) {
    //Se pasa a Mayusculas
    IBAN = IBAN.toUpperCase();
    //Se quita los blancos de principio y final.
    IBAN = IBAN.trim();
    IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

    var letra1,letra2,num1,num2;
    var isbanaux;
    var numeroSustitucion;
    //La longitud debe ser siempre de 24 caracteres
    if (IBAN.length != 24) {
        return false;
    }

    // Se coge las primeras dos letras y se pasan a números
    letra1 = IBAN.substring(0, 1);
    letra2 = IBAN.substring(1, 2);
    num1 = this.getnumIBAN(letra1);
    num2 = this.getnumIBAN(letra2);
    //Se sustituye las letras por números.
    isbanaux = String(num1) + String(num2) + IBAN.substring(2);
    // Se mueve los 6 primeros caracteres al final de la cadena.
    isbanaux = isbanaux.substring(6) + isbanaux.substring(0,6);

    //Se calcula el resto, llamando a la función modulo97, definida más abajo
    var resto = this.modulo97(isbanaux);
    if (parseInt(resto) == 1){
        return true;
    }else{
        return false;
    }
}
modulo97(iban) {
  var parts = Math.ceil(iban.length/7);
  var remainer = "";

  for (var i = 1; i <= parts; i++) {
      remainer = String(parseFloat(remainer+iban.substr((i-1)*7, 7))%97);
  }

  return remainer;
}

getnumIBAN(letra) {
  var ls_letras = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  return ls_letras.search(letra) + 10;
}

}
