import { Component, OnInit } from '@angular/core';
import { CatalogService } from './services/catalog.service';
import { TokenService } from '../../services/token.service';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Catalog } from './interfaces/catalog';
import { SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnInit {

  catalogs: Catalog[] = [];

  pages : number ;
  constructor(private catalogService: CatalogService, private tokenService : TokenService, private _router : Router, private Auth : AuthService,
  private Notify: SnotifyService) { }

  ngOnInit() {

    this.catalogService.getCatalogs(1).subscribe(
      data => {
        
        this.catalogs = data['catalogs'].data;
        this.pages = data['catalogs'].last_page;

      },
      error=> this.handleError(error)
    )
  } 
  getPageCatalog(page){
    this.catalogService.getCatalogs(page+1).subscribe(
      data => {
        this.catalogs = data['catalogs'].data;
        this.pages = data['catalogs'].last_page;
      },
      error=> this.handleError(error)
    )
  }

  handleError(error){
    if(error.error[0]){
      this.Auth.changeAuthStatus(false);
      this.tokenService.remove();
      this._router.navigateByUrl('login');
    }
  }

 numbersToArray(n: number): any[] {
    return Array(n);
  }

  deleteCatalog(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este catálogo?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{

          this.catalogService.deleteCatalog(_id).subscribe(
            data => {
            this.catalogs.splice(index,1);
            },
            error => this.Auth.handleError(error)
      
          )
            this.Notify.remove(toaster.id)
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });



  }

}
