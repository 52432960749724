import { Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import { PrestashopService } from './../../services/prestsahop.service'
import { AuthService } from './../../services/auth.service'
import { CombinationService } from '../combinations/services/combination.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidatorFn } from '@angular/forms';
import { Price } from './interfaces/prices';
import { Combination } from './../combinations/interfaces/combination';
import { ProductOption, ProductOptionValues } from './../combinations/interfaces/product_options';
import { UtilService } from '../../services/utils.service';
import { PriceService } from './services/price.service';

@Component({
  selector: 'prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PricesComponent implements OnInit {

/*   combination: Combination =   */
  @Input() combinations: Combination[];
  @Input() combinationsPrice: any[] =[];
  @Input() prices: Price[];
  @Input() price: number;
  @Input() idproduct: string;
  @Input() attributes : ProductOption[]=[];
  @Input() attribute_values : ProductOptionValues[]=[];

  @Input() pages:number;
  @Input() page:number = 1;
  @Input() cant:number = 10;
  selectedCombinationIndex: number = 0; 

  currencies: any = [];
  countries: any = [];
  groups: any = [];
  constructor(private _router : Router, 
              private auth : AuthService,
              private ps : PrestashopService,
              private utils: UtilService,
              private priceService: PriceService,
              private Notify: SnotifyService) {
               }

  ngOnInit() {
    this.ps.getPS('currencies',"",'[id,name]',[{key:'active', value:'1'}]).subscribe(
      data => {
        this.currencies = data['currencies'] ? this.utils.addAll(data['currencies'], 'currencies') : []
      }
    )
    this.ps.getPS('countries',"",'[id,name]',[]).subscribe(
      data => {
        this.countries = data['countries'] ? this.utils.addAll(data['countries'], 'countries') : []
      }
    )
    this.ps.getPS('groups',"",'[id,name]',[]).subscribe(
      data => {
        this.groups = data['groups'] ? this.utils.addAll(data['groups'], 'groups') : []
      }
    )
    console.log(this.prices)
/*     this.createCombinationsForPrice(this.combinations,this.attributes,this.attribute_values) */
/*       console.log(this.combinations)
      console.log(this.price)
      console.log(this.prices)
      console.log(this.attributes) */
  }
  create(price){
    this.prices.push(price)
  }

/*   createCombinationsForPrice(combinations, attributes, attribute_values){
    let combinationsPrices = []
    combinations.forEach(combination => {
      combinationsPrices.push({id: combination._id, name: this.utils.getAttributeValueOfCombination(combination, attributes, attribute_values)})
    })
    return this.utils.addAll([...combinationsPrices], 'combinations')
  } */

  update(data){
    if(data._id){
      let price = this.prices.find(pric => {
        return pric._id = data._id;
      })
      let position = this.prices.lastIndexOf(price);
      this.prices[position] = data;
    }else{
     this.prices[this.selectedCombinationIndex] = data; 
    }
  }

  /*
  create(price){
    this.prices.push(price)
  }
  update(data){
    if(data._id){
      let combination = this.combinations.find(comb => {
        return comb._id = data._id;
      })
      let position = this.combinations.lastIndexOf(combination);
      this.combinations[position] = data;
    }else{
     this.combinations[this.selectedCombinationIndex] = data; 
    }
  }
  
  getNameAttributeByID(id){
    let attribute = this.attributes.find((attribute) => {
      return id == attribute.id
    })
    if(attribute){
      return this.contieneIdiomas(attribute.public_name);
    }
  }
  getAttributeValueOfCombination(c:Combination){
    let name = ""
    c.associations.product_option_values.forEach(association_id => {
      let value = this.attribute_values.find((val) =>{
        return val.id == association_id
      })
      if(!value){
        name = 'Not found'
        return
      }
      let attribute = this.attributes.find((attr) => {
        return attr.id == value.id_attribute_group
      })
      if(!attribute){
        name = 'Not found'
        return
      }
      if(!name){
        name = this.contieneIdiomas(attribute.public_name) +':'+ this.contieneIdiomas(value.name);
      }else{
        name += ", "+this.contieneIdiomas(attribute.public_name)+':'+ this.contieneIdiomas(value.name);
      }
    });
    return name;
  }

  updateCombination(_id: string, index:number){

    console.log(_id)
    console.log(index)

  }*/

  deletePrice(_id : string, index : number){

    this.Notify.confirm('¿Seguro que quieres eliminar este price ?', {
      buttons:[
        {text : 'Eliminar', 
        action: toaster =>{
          this.priceService.deletePrice(_id).subscribe(
            data => {
              this.prices.splice(index, 1)
              this.Notify.remove(toaster.id)
            },
            error => this.auth.handleError(error)

          )
            }
        },
        {text : 'Cancelar', 
        action: toaster =>{
            this.Notify.remove(toaster.id)
            }
        }
      ]
    });
  }
  
}