import { Component, OnInit } from '@angular/core';
import { CatalogService } from './services/catalog.service';
import { TokenService } from '../../services/token.service';
import { AuthService } from '../../services/auth.service';
import { Catalog } from './interfaces/catalog';
import { Router, ActivatedRoute } from '@angular/router';
import { SnotifyService } from 'ng-snotify';
import { NgForm } from '@angular/forms';



@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html'
})
export class CatalogComponent implements OnInit {

  catalog:Catalog = {
    _id:"",
    image: "",
    name : ""
  }

  nuevo: boolean = false;
  id:string;

  constructor(private catalogService : CatalogService, 
              private tokenServide : TokenService, 
              private auth: AuthService, 
              private _router : Router,
              private activatedRoute : ActivatedRoute,
              private Notify: SnotifyService) {

              this.activatedRoute.params.subscribe(
                parametros =>{
                  this.id=parametros['id'];
                  if(this.id != 'add'){
                    this.catalogService.getCatalog(this.id).subscribe(
                      data => {
                        this.catalog = data['catalog'];
                      },
                      error => this.auth.handleError(error)
                    );
                  }
                }
              )
            }

  ngOnInit() {
  }

  guardar(){
    if(this.id == 'add'){
      //insert
      this.catalogService.newCatalog(this.catalog).subscribe(
        data=>{
          this.Notify.success("Catálogo creado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/catalogs']);
          },2000);

          //this._router.navigate(['/catalogs',data['catalog']._id])
        },
        error => this.auth.handleError(error)
      )
    }else{
      //update
      this.catalogService.updateCatalog(this.catalog, this.id).subscribe(
        data=>{
         // this._router.navigate(['/catalogs',data['catalog']._id])
         this.Notify.info("Catálogo editado",{timeout:1000});//0 = gets default timeout
          setTimeout(() => {
            this._router.navigate(['/catalogs']);
          },2000);
        },
        error => this.auth.handleError(error)
      )
    }
  
  }

  agregarNuevo(form : NgForm){
    this._router.navigate(['/catalog','add']);
    form.reset();

  }

}
