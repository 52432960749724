import { Injectable, } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../../services/auth.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ProfileResolver implements Resolve<Object> {
    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return  this.authService.getProfile('test');
    }
}
