import { Injectable } from '@angular/core';
import { TokenService } from '../../../services/token.service';
import { AuthService } from '../../../services/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
/* import { Product } from '../interfaces/product'; */

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private auth : AuthService,private http : HttpClient, private Token: TokenService) { }


  getOrders(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/orders',{ headers });
  }

  getStates(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/states/orders',{ headers });
  }

  updateStateOrder(idOrder, idState){
    let order_data = JSON.stringify({id_status: idState, id_order: idOrder});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         
    
    return this.http.post(environment.url_api+environment.api+'/change/state_order',order_data, { headers });
  }

/*   getProductsExport(){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
                     
     return this.http.get(environment.url_api+environment.api+'/export/products',{ headers });
  }

  importProducts(products: Product[]){
    let products_data = JSON.stringify({products: products});
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/import/products',products_data, { headers });
  }

  newProduct(product: Product){
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    return this.http.post(environment.url_api+environment.api+'/products',product_data, { headers });
  }

  updateProduct(product: Product, _id:string){
    let product_data = JSON.stringify(product);
    let headers = new HttpHeaders()
                      .set('Content-Type', 'application/json')
                      .set('authorization', 'Bearer '+this.Token.get() );         

    
    return this.http.put(environment.url_api+environment.api+'/products/'+_id,product_data, { headers });
  }

  getProduct(_id:string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.get(environment.url_api+environment.api+'/products/'+_id,{ headers });

  }
  deleteProduct(_id : string){
    let headers = new HttpHeaders()
                     .set('Content-Type', 'application/json')
                     .set('authorization', 'Bearer '+this.Token.get() );         
     
     return this.http.delete(environment.url_api+environment.api+'/products/'+_id,{ headers });
  } */
}
