import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SnotifyService } from 'ng-snotify';
import { ImportService } from './services/import.service';
import { Stock } from './../stocks/interfaces/stocks'
import { Product } from './../products/interfaces/product'
import { Category } from './../categories/interfaces/category'
import { ProductsService } from '../products/services/products.service';
import { CategoryService } from '../categories/services/category.service';
import { Price } from '../prices/interfaces/prices';
import { Combination } from '../combinations/interfaces/combination';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.css']
})
export class ImportsComponent implements OnInit {

  images:any;
  form: FormGroup;
  prestaform: FormGroup = new FormGroup({
                 
  });

  resourceName: string = '';
  products: Product[] = [];
  stocks: Stock[] = [];
  categories: Category[] = [];
  combinations: Combination[] = [];
  prices: Price[] = [];
  constructor(private importService : ImportService, 
              private productService : ProductsService,
              private categoryService: CategoryService,
              private Notify: SnotifyService,
              private papa: Papa,
              private fb: FormBuilder) {

           
                this.form = this.fb.group({
                  name: ['', Validators.required],
                  csv: null
                });

            }

  ngOnInit() {
  }
  getFiles(event) {
    this.images = event.target.files;
}

onFileChange(event, resourceName) {
  this.resourceName = resourceName
  let reader = new FileReader();
  if(event.target.files && event.target.files.length > 0) {
    let file = event.target.files[0];
    reader.readAsText(file);
    reader.onload = this.readCsvFile.bind(this);
  }
}

  guardar(nameResource){
    switch (nameResource) {
      case 'products':
        this.productService.importProducts(this.products).subscribe(
          data=>{
            this.Notify.success('Productos importados',{timeout:1000})
          }
        )
        break;
      case 'categories':
        this.categoryService.importCategories(this.categories).subscribe(
          data=>{
            this.Notify.success('Categorias importadas',{timeout:1000})
          }
        )
        break;
      case 'stocks':
        this.importService.importStocks(this.stocks).subscribe(
          data=>{
            this.Notify.success('Stocks importados',{timeout:1000})
          }
        )
        break;
      case 'combinations':
        this.importService.importCombinations(this.combinations).subscribe(
          data=>{
            this.Notify.success('Combinations importados',{timeout:1000})
          }
        )
        break;
      case 'prices':
        this.importService.importPrices(this.prices).subscribe(
          data=>{
            this.Notify.success('Prices importados',{timeout:1000})
          }
        )
        break;
    
      default:
        break;
    }

  }
  readCsvFile(e){
    let options = {
      complete: (results) => {
          console.log('Parsed: ', results);
          console.log(this.resourceName)
          if(results && results.data){
            this.switchProccess(results.data)
          }
      }
    }
    this.papa.parse(e.target.result,options);
/*     let result = []
    let csvText = e.target.result;
    let csv = csvText.split(/\r|\n|\r/);
    let headers = csv[0].substring(1, csv[0].length -1).split('";"');
    for (let i = 1; i <= csv.length-1; i++) {  
      let item = {}
      let currentline = csv[i].substring(1, csv[i].length - 1).split('";"');
      for (let j = 0; j < headers.length; j++){
        item[headers[j]] = currentline[j]
        }
      result.push(item)
    }  
    switch (this.resourceName) {
      case 'products':
        this.products = this.processProducts(result);
        break;
      case 'categories':
        this.categories = result;
        break
      case 'stocks':
        this.stocks = result;
        break
      case 'prices':
        this.prices = result;
        break
      case 'combinations':
        this.combinations = this.processCombinations(result);
        break
      default:
        break;
    }
    this.resourceName = '' */
  }

  switchProccess(result){
    console.log(result)
    if(result && result.length > 0){
      switch (this.resourceName) {
        case 'products':
          this.products = this.processProducts(result);
          break;
        case 'categories':
          this.categories = result;
          break
        case 'stocks':
          this.stocks = result;
          break
        case 'prices':
          this.prices = result;
          break
        case 'combinations':
          this.combinations = this.processCombinations(result);
          break
        default:
          break;
      }
    }
  }
  processProducts(productsCSV){
    let products = [];
    let header = productsCSV[0];
    header[0] = header[0]+"(COMBINACION)";
    delete productsCSV[0];
    products = productsCSV.map((item) => { 
      if(item && item.length > 0){
        let product = {};
        header.forEach((h,i) => {
          product[h]=item[i];
          })

        let descripcion = [
                            {iso_code: 'es', value: product['DESCRIPCION(ES)']},
                            {iso_code: 'ca', value: product['DESCRIPCION(CAT)']},
                            {iso_code: 'fr', value: product['DESCIPCION(FR)']},
                            {iso_code: 'en', value: product['DESCRIPCIO(EN)']},
                          ]
        let COMPOS = product['COMPOS(ES)'];
        delete product['COMPOS(ES)'];
        delete product['DESCRIPCION(ES)'];
        delete product['DESCRIPCION(CAT)'];
        delete product['DESCIPCION(FR)'];
        delete product['DESCRIPCIO(EN)'];
        if(product != {}){
          return {...product, descripcion, COMPOS};
        }
      }
    })
    products = products.filter(product => product != undefined)
    console.log(products)
    return products
/*     productsCSV.forEach(productCSV => {
      let product = { ...productCSV };
      let key_images = Object.keys(product).filter(key => {
        return key.match('image[0-9]{1,2}$');
      });
      let images = {}
      key_images.forEach(key_image => {
        images[key_image] = product[key_image]
        delete product[key_image]
      })
      product['images'] = images
      product['categories'] = product['categories'].split(',');
      product['active'] = JSON.parse(product['active'])
      products.push(product);
    });
    return products */
  }
  processCombinations(combinationsCSV){
    let combinations = []
    combinationsCSV.forEach(combinationCSV => {
      if(combinationCSV['associations']){
        combinationCSV['associations'] = {'product_option_values': combinationCSV['associations'].split(',')}
      }else{
        combinationCSV['associations'] = {'product_option_values': []}
      }
      combinations.push(combinationCSV); 
    });
    return combinations;
  }
}
